import { CustomDateAdapter } from './adapters/custom-date-adapter';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './modules/material.module';
import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxGalleryModule } from 'ngx-gallery';
import { SliderModule, CarouselModule } from 'primeng';
import { SliderService } from '../slider.service';
@NgModule({
    declarations: [ DateFormatPipe
    ],
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      MaterialModule,
      SlickCarouselModule,
      NgxGalleryModule,
      SliderModule,
      CarouselModule
    ],
    providers: [
      { provide: DateAdapter, useClass: CustomDateAdapter },
      { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
      { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
      SliderService
    ],
    exports: [
      FormsModule,
      ReactiveFormsModule,
      MaterialModule,
      DateFormatPipe,
      NgxGalleryModule,
      SliderModule,
      CarouselModule
    ],
  })
  export class SharedModule {

  }
