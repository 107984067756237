import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { UiService } from '../core/services/ui.services';
import { RegionMaster } from '../models/region-master';
import { RegionMasterService } from '../services/region-master.service';
import { MatPaginator, MatSlideToggleChange, MatSort } from '@angular/material';

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.css']
})
export class RegionComponent implements OnInit {
  clsRegionMaster = new RegionMaster();
  clsRegionMasterArray = Array<RegionMaster>();
  screenWidth: number;
  displayedColumns: string[] = ['Id', 'regionCode', 'regionName' , 'IsActive', 'EditDelete'];
  dataSource: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private formBuilder: FormBuilder,
    private uiService: UiService,
    private regionMasterService: RegionMasterService
  ) {
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
    };
    this.initRegionmasterForm(new RegionMaster());
  }
  regionForm: FormGroup;
  ngOnInit() {
   // window.location.l
    this.getAllRegionMaster();
  }
  public toggle(event: MatSlideToggleChange) {
    
    const checkedValue = event.checked;
    if(checkedValue == true){
      this.regionForm.controls.isActive.setValue(1);
    }
    if(checkedValue == false){
      this.regionForm.controls.isActive.setValue(0);
    }
  }
  initRegionmasterForm(m: RegionMaster) {
    this.regionForm = this.formBuilder.group({
      id: [m.id == null ? 0 : m.id],
      regionCode: [m.regionCode == null ? '' : m.regionCode, Validators.required],
      regionName: [m.regionName == null ? '' : m.regionName, Validators.required],
      isActive: [m.isActive, Validators.required],
    });
  }
  cancel(){
    
  }
  create() {
    this.clsRegionMaster = this.regionForm.getRawValue();
    if (this.clsRegionMaster.id === 0) {
      this.uiService
        .showConfirmationBox('Create', 'Are you sure you want to create ?....')
        .subscribe(result => {
          if (result) {
            this.regionMasterService.create(this.clsRegionMaster).subscribe(res => {
              if(res!='region-exist'){
              this.uiService.showSuccess(`${res.regionName} was created`);
              this.clsRegionMaster = new RegionMaster();
              this.initRegionmasterForm(new RegionMaster());
              this.getAllRegionMaster();
              } else{
                this.uiService.showError(this.clsRegionMaster.regionName +"Or" + this.clsRegionMaster.regionCode +"already exists");
              }
            });
          }
        });
    } else {
      this.uiService
        .showConfirmationBox('Update', 'Are you sure you want to update ?...')
        .subscribe(result => {
          if (result) {
            this.regionMasterService.update(this.clsRegionMaster).subscribe(res => {
              if(res!='region-exist'){
              this.uiService.showSuccess(`${res.regionName} was updated`);
              this.clsRegionMaster = new RegionMaster();
              this.initRegionmasterForm(new RegionMaster());
              this.getAllRegionMaster();
              } else{
                this.uiService.showError(this.clsRegionMaster.regionName +"Or" + this.clsRegionMaster.regionCode +"already exists");
              }
            });
          }
        });
    }
  }
  
 
  getAllRegionMaster() {
    this.regionForm.controls.isActive.setValue(1);
    this.regionMasterService.list().subscribe(res => {
      console.log('ggg',res)
      this.clsRegionMasterArray = res;
      this.dataSource  = this.clsRegionMasterArray;
      this.dataSource.paginator = this.paginator;

    });
  }
  Edit(element: any) {
    debugger
    const clsRegionMasterArray = new RegionMaster();
    clsRegionMasterArray.id = element.id;
    clsRegionMasterArray.regionCode = element.regionCode;
    clsRegionMasterArray.regionName = element.regionName;
    clsRegionMasterArray.isActive = element.isActive;
    this.initRegionmasterForm(clsRegionMasterArray);
  }
  Delete(element: any) {
    const clsRegionMasterr = new RegionMaster();
    clsRegionMasterr.id = element.id;
    clsRegionMasterr.regionCode = element.regionCode;
    clsRegionMasterr.regionName = element.regionName;
    clsRegionMasterr.isActive = element.isActive;
    this.uiService
    .showConfirmationBox('Delete', 'Are you sure you want to delete ?...')
    .subscribe(result => {
      if (result) {
        this.regionMasterService.delete(clsRegionMasterr).subscribe(res => {
          
          if (res.id > 0) {
            this.uiService.showSuccess(`${clsRegionMasterr.regionName} was deleted`);
            this.clsRegionMaster = new RegionMaster();
            this.initRegionmasterForm(new RegionMaster());
            this.getAllRegionMaster();
          }

        });
      }
      });

  }

}
