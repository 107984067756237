import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger, MatPaginator, MatSlideToggleChange, MatSort, MatTableDataSource } from '@angular/material';
import { VehicleMasterService } from '../services/vehicle-master.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UiService } from '../core/services/ui.services';
import { VehicleMaster } from '../models/vehicle-master';
import { MakeMaster } from '../models/make-master';
import { MakeMasterService } from '../services/make-master.service';
import { UploadImage } from '../models/vehicle-registration';

@Component({
  selector: 'app-make',
  templateUrl: './make.component.html',
  styleUrls: ['./make.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MakeComponent implements OnInit {
  screenWidth: number;
  makemasterForm: FormGroup;
  selectedFiles: FileList;
  uploadImage = new Array<UploadImage>();
  constructor(private vehicleMasterService: VehicleMasterService,
              private makeMasterService: MakeMasterService,
              private formBuilder: FormBuilder,
              private uiService: UiService,  private cd: ChangeDetectorRef) {
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
    };
    this.initMakemasterForm(new MakeMaster() );
  }
  clsMakeMaster = new MakeMaster();
  displayedColumns: string[] = ['Id', 'VehicleName', 'MakeName',  'IsActive', 'EditDelete'];
  dataSource: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  @ViewChild(MatMenuTrigger, { static: true }) clickHoverMenuTrigger: MatMenuTrigger;
  clsVehicleMasterArray = new Array<VehicleMaster>();
  clsMakeMasterArray = new Array<MakeMaster>();
  ngOnInit() {
    this.getAllVehicleMaster();
    this.getAllMakeMaster();
    this.makemasterForm.controls.isActive.setValue(1);
  }
  initMakemasterForm(m: MakeMaster) {
    this.makemasterForm = this.formBuilder.group({
      id: [m.id == null ? 0 : m.id],
      makeName: [m.makeName == null ? '' : m.makeName, Validators.required],
      isActive: [m.isActive, Validators.required],
      vehiclemasterId: [m.vehiclemasterId == null ? '' : m.vehiclemasterId, Validators.required]
    });
  }
  getAllVehicleMaster() {
    this.vehicleMasterService.list().subscribe(res => {
      this.clsVehicleMasterArray = res;
    });
  }

  public toggle(event: MatSlideToggleChange) {
    
    const checkedValue = event.checked;
    if(checkedValue == true){
      this.makemasterForm.controls.isActive.setValue(1);
    }
    if(checkedValue == false){
      this.makemasterForm.controls.isActive.setValue(0);
    }
  }

  getAllMakeMaster() {
    this.makeMasterService.list().subscribe(res => {
      
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
      this.clsMakeMasterArray = res;
    });
  }
  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      this.selectedFiles = event.target.files;
    }
  }
  save() {
    this.clsMakeMaster = this.makemasterForm.getRawValue();
    if (this.clsMakeMaster.id === 0) {
      this.uiService
        .showConfirmationBox('Create', 'Are you sure you want to create ?....')
        .subscribe(result => {
          if (result) {
            this.makeMasterService.create(this.clsMakeMaster).subscribe(res => {
              if(res!='make-exist'){
              this.uiService.showSuccess(`${res.makeName} was created`);
              if(this.selectedFiles!=undefined){
                this.makeMasterService.PhotoUplaod(this.selectedFiles, res.id).subscribe(res1 => {
                });
              }
              this.clsMakeMaster = new MakeMaster();
              this.initMakemasterForm( new MakeMaster());
              this.cancel();
              }
            });
          }
          else{
            this.uiService.showError(`${this.clsMakeMaster.makeName} already exists`);   
          }
        });
    } else {
      this.uiService
        .showConfirmationBox('Update', 'Are you sure you want to update ?...')
        .subscribe(result => {
          if (result) {
            this.makeMasterService.update(this.clsMakeMaster).subscribe(res => {
              if(res!='make-exist'){
              this.uiService.showSuccess(`${res.makeName} was updated`);
              if(this.selectedFiles!=undefined){
                this.makeMasterService.PhotoUplaod(this.selectedFiles,  res.id).subscribe(res1 => {
                });
              }
              this.clsMakeMaster = new MakeMaster();
              this.initMakemasterForm(new MakeMaster());
              this.cancel();
              }
              else{
                this.uiService.showError(`${this.clsMakeMaster.makeName} already exists`);
              }
            });
          }
          });
    }

  }
  validateFileUpload() {
    if (this.selectedFiles !== undefined ) {
      if (this.selectedFiles.length > 0) {
        return true;

      } else {
        this.uiService.showWarning('Please upload atleast 1 images');
        return false;
      }
    } else {
      if( this.uploadImage.length>0 )
      {
        return true;
      }
      else{
        this.uiService.showWarning('Please upload atleast 1 images');
        return false;
      }
    
    }

  }
  Edit(element: any) {
    debugger
    this.uploadImage = new Array<UploadImage>();
    const clsMakeMaster = new MakeMaster();
    clsMakeMaster.id = element.id;
    clsMakeMaster.makeName = element.makeName;
    clsMakeMaster.isActive = element.isActive;
    clsMakeMaster.vehicleName = element.vehicleName;
    clsMakeMaster.vehiclemasterId = element.vehiclemasterId;
    this.uploadImage=[]=[];
      if (element.uploadImageList.length > 0) {
        for (let index = 0; index < element.uploadImageList.length; index++){
          this.uploadImage.push({ 
            id:element.uploadImageList[index].id,
            hostFilePath:  element.uploadImageList[index].hostFilePath,
            fullFilePath:  element.uploadImageList[index].fullFilePath,
            fileName:  element.uploadImageList[index].fileName,
            tblVehicleRegistrationId:  element.uploadImageList[index].tblMakeMasterId,
           });
        }
      }
    this.initMakemasterForm(clsMakeMaster);
  }
  cancel() {
    this.initMakemasterForm( new MakeMaster());
    this.getAllVehicleMaster();
    this.getAllMakeMaster();
    this.uploadImage = new Array<UploadImage>();
    this.uploadImage=[]=[];
    this.makemasterForm.controls.isActive.setValue(1);
  }
  Delete(element: any) {
    const clsMakeMaster = new MakeMaster();
    clsMakeMaster.id = element.id;
    clsMakeMaster.makeName = element.makeName;
    clsMakeMaster.isActive = element.isActive;
    this.uiService
    .showConfirmationBox('Delete', 'Are you sure you want to delete ?...')
    .subscribe(result => {
      if (result) {
        this.makeMasterService.delete(clsMakeMaster).subscribe(res => {
          if (res.id > 0) {
            this.uiService.showSuccess(`${clsMakeMaster.makeName} was deleted`);
            this.initMakemasterForm( new MakeMaster());
            this.getAllVehicleMaster();
            this.getAllMakeMaster();
            this.cancel();
          }

        });
      }
      });

  }
   // tslint:disable-next-line:use-lifecycle-interface
   ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  removeAt(value: any, index) {
    this.uiService
      .showConfirmationBox('Delete', 'Are you sure you want to delete ?...')
      .subscribe(result => {
        if (result) {
          this.makeMasterService.deletePhotoByid(value.id).subscribe(res => {
            if (res > 0) {
              this.clickHoverMenuTrigger.closeMenu();
              this.uiService.showSuccess(`${value.fileName} was deleted`);
              this.uploadImage.splice(index, 1);
            }
          });
        }
      });
  }
}


