

export class UserMaster {
    id=0;
    firstName='';
    lastName='';
    username='';
    password='';
    token='';

}