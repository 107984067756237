import { Component, OnInit } from '@angular/core';

import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER, NINE} from '@angular/cdk/keycodes';
import * as $ from 'jquery';
import { SliderService } from '../slider.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryLayout } from 'ngx-gallery';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../core/services/ui.services';
import { MakeMaster } from '../models/make-master';
import { ModelMaster } from '../models/model-master';
import { RegionMaster } from '../models/region-master';
import { HomeSearch } from '../models/search-home';
import { CommonMasterService } from '../services/common.service';
import { FeatureMasterService } from '../services/feature.service';
import { MakeMasterService } from '../services/make-master.service';
import { ModelMasterService } from '../services/model-master.service';
import { RegionMasterService } from '../services/region-master.service';
import { SearchAllService } from '../services/searchAll.service';
import { VehicleMasterService } from '../services/vehicle-master.service';
import { VehicleRegistrationService } from '../services/vehicle-registration.service';
import { FeatureMaster } from '../models/feature-master';
import { VehicleMaster } from '../models/vehicle-master';
import { InterestedEmailModel } from '../models/email-body';
import { Details } from '../models/details-master';
import * as data from "../../assets/yearandprice.json"
export interface Category {
  name: string;
}
@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  // galleryImages: NgxGalleryImage[];
  galleryImages: NgxGalleryImage[] = [];
  LuxuryOpenState=false;
  details=new Details();
  screenWidth: number;
  YearOpenState:any;
  BudgetOpenState:any;  
  TransmissionOpenState:any;  
  BrandOpenState:any;
  objvehicleRegistrationService: any[] = [];
  vehiclerSearchForm: FormGroup;
  // details: any[] = [];
  interestedEmailModel=new InterestedEmailModel();
  clsRegionMasterArray = Array<RegionMaster>();
  clsVehicleMasterArray = Array<VehicleMaster>();
  clsMakeMasterArray = new Array<MakeMaster>();
  clsModelMasterArray = new Array<ModelMaster>();
  featureMasterArray = new Array<FeatureMaster>();
  featureArrayChanks = new Array<any>();
  ChktbltransmitionTypeManual = false;
  ChktbltransmitionTypeAutomatic = false;
  price="5000"+"-"+"10000000";
  year="1800"+"-"+"2021";
  tblregionId=0;
  vehicle_model="";
  name="";
  email="";
  mobile="";
  link="";
  public href: string = "";

  minYear:any;
  maxYear:any;
  minPrice:any;
  maxPrice:any;
  product: any =(data as any ).default;
  priceInWords:any;
  constructor(private vehicleRegistrationService: VehicleRegistrationService,
    private vehicleMasterService: VehicleMasterService,
    private makeMasterService: MakeMasterService,
    private formBuilder: FormBuilder,
    private uiService: UiService,
    private regionMasterService: RegionMasterService,
    private modelMasterService: ModelMasterService,
    private commonMasterService: CommonMasterService,
    private featureMasterService: FeatureMasterService,
    private searchAllService: SearchAllService,
    private router: Router,
    private route :ActivatedRoute,
    
  ) {
    this.href = this.router.url;
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
    };
    this.initVehicleSearch(new HomeSearch);
   }
   slides = [
    {img: "assets/images/carimg.png"},
    {img: "assets/images/carimg2.png"},
    {img: "assets/images/carimg.png"},
    {img: "assets/images/carimg2.png"},
    {img: "assets/images/carimg.png"},
    {img: "assets/images/carimg2.png"},
  ];
  slideConfig = {
    slidesToShow: 5, slidesToScroll: 1, responsive: [
    {
    breakpoint: 1024,
    settings: {
    slidesToShow: 3,
    initialSlide: 1,
    arrows: true,
    dots: true
    }
    },
    {
    breakpoint: 600,
    settings: {
    slidesToShow: 2
    }
    },
    {
    breakpoint: 480,
    settings: {
    slidesToShow: 1
    }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
    ]
    };
val: number[] = [0,100];


next() {
  this.val[0]++;
  console.log(this.val)
} 

 
  
  slickInit(e) {
    console.log('slick initialized');
  }
  
  breakpoint(e) {
    console.log('breakpoint');
  }
  
  afterChange(e) {
    console.log('afterChange');
  }
  
  beforeChange(e) {
    console.log('beforeChange');
  }
 
   private removeFirst<T>(array: T[], toRemove: T): void {
     const index = array.indexOf(toRemove);
     if (index !== -1) {
       array.splice(index, 1);
     }
   }

  ngOnInit(): void {
    var d = new Date();
    var n = d.getFullYear();
    this.price=data["default"]["Price"][0]+"-"+data["default"]["Price"][1];
    this.year=data["default"]["year"]+"-"+n;
    this.minYear=data["default"]["year"];
    this.maxYear=n;
    this.minPrice=data["default"]["Price"][0];
    this.maxPrice=data["default"]["Price"][1];

    this.route.queryParams.subscribe(params => {
      if(params['detailsId']){
        this.searchAllService.SearchByDetails(params['detailsId']).subscribe(res=>{
          if(res.length>0){
            this.details=res[0];
            this.priceInWords= this.details.price ;  // this.intToEnglish(23424664.66 ) // this.details.price
            this.vehicle_model=res[0].modelName;
            if(res[0].uploadImageList.length>0){
              for (let index = 0; index < res[0].uploadImageList.length; index++) {
                this.galleryImages.push({
                  'small':res[0].uploadImageList[index].hostFilePath,
                  'medium': res[0].uploadImageList[index].hostFilePath , 
                  'big': res[0].uploadImageList[index].hostFilePath    })
              }
            }
          }
          if(res[0].featureId.length>0){
           this.featureArrayChanks= this.chunkArrayInGroups(res[0].featureId, 3);
          }
    
  
        });
      }

      
       
      
     
   });

   

    this.getAllRegionMaster();
    this.getAllVehicleMaster();
    this.getAllFeature();
 

    this.galleryOptions = [
        {
            width: '300px',
            height: '300px',
            thumbnailsColumns: 4,
            imageAnimation: NgxGalleryAnimation.Zoom
        },
        // max-width 800
        {
            breakpoint: 800,
            width: '100%',
            height: '600px',
            imagePercent: 80,
            thumbnailsPercent: 20,
            thumbnailsMargin: 20,
            thumbnailMargin: 20,
        },
        // max-width 400
        {
            breakpoint: 400,
            preview: false
        }
    ];

}

 chunkArrayInGroups(arr, size) {
  var myArray = [];
  for(var i = 0; i < arr.length; i += size) {
    myArray.push(arr.slice(i, i+size));
  }
  return myArray;
}

  // ============================================
  // region
  // =======================================
  getAllRegionMaster() {
    this.commonMasterService.RegionMasterlist().subscribe(res => {
      this.clsRegionMasterArray = res;
    });
  }

  getAllregistration() {
    this.commonMasterService.GetVehicleRegistrationlist().subscribe(res => {
      this.objvehicleRegistrationService = res;
    });

  }
  getAllVehicleMaster() {
    this.commonMasterService.VehicleMasterlist().subscribe(res => {
      this.clsVehicleMasterArray = res;
    });
  }
  async onvehicleMasterChange(event: any) {
    const value = event.value;
    this.vehiclerSearchForm.get('tblVehicleId').setValue(value);
    this.vehiclerSearchForm.get('tblMakeId').setValue(0);
    this.vehiclerSearchForm.get('tblModelId').setValue(0);
    await this.getAllMakeMaster(value);
  }
  async getAllMakeMaster(value: any) {
    const query = value;
    this.commonMasterService.listByVehicleMasterId(query).subscribe(res => {
      this.clsMakeMasterArray = new Array<MakeMaster>();
      this.clsModelMasterArray = new Array<ModelMaster>();
      this.clsMakeMasterArray = res;
    });
  }
  async getAllModels(makeId: any) {
    this.clsModelMasterArray = new Array<ModelMaster>();
    this.commonMasterService.listModelByMakeId(makeId).subscribe(res => {
      this.clsModelMasterArray = res;
    });
  }
  async onvehicleMakeChange(event: any) {
    const value = event.value;
    await this.getAllModels(value);
    this.vehiclerSearchForm.get('tblMakeId').setValue(value);
    this.vehiclerSearchForm.get('tblModelId').setValue(0);
  }

  searchKeyValue(searchKeyword: string) {
    this.clsRegionMasterArray = Array<RegionMaster>();
    if (searchKeyword != '') {
      this.commonMasterService.listBysearchKeyWord(searchKeyword).subscribe(
        res => {
          if (res) {
            this.clsRegionMasterArray = res;
          } else {
            const removeStr = searchKeyword.substring(0, searchKeyword.length - 1);
            this.vehiclerSearchForm.controls['tblregionId'].setValue(removeStr);
            this.searchKeyValue(removeStr);
          }
        },
        error => {
          console.log(error);
        }
      );
    } else {
      this.getAllRegionMaster();
    }

  }

  initVehicleSearch(m: HomeSearch) {
    var d = new Date();
    var n = d.getFullYear();
    this.price=data["default"]["Price"][0]+"-"+data["default"]["Price"][1];
    this.year=data["default"]["year"]+"-"+n;
    this.minYear=data["default"]["year"];
    this.maxYear=n;
    this.minPrice=data["default"]["Price"][0];
    this.maxPrice=data["default"]["Price"][1];
    this.vehiclerSearchForm = this.formBuilder.group({
      tblVehicleId: [m.tblVehicleId == null ? 0 : m.tblVehicleId],
      tblMakeId: [m.tblMakeId == null ? 0 : m.tblMakeId],
      tblModelId: [m.tblModelId == null ? 0 : m.tblModelId],
      tblregionId: [m.tblregionId] == null ? 0 : m.tblregionId,
      tblFuelTypeID: [m.tblFuelTypeID == null ? 0 : m.tblFuelTypeID],
      year: [[this.minYear, this.maxYear]],
      price: [[this.minPrice, this.maxPrice]],
      tbltransmitionTypeID: [m.tbltransmitionTypeID == null ? 0 : m.tbltransmitionTypeID],
      featureId: [m.featureId]
    });
  }

  transmitionCheckManual(event) {
    
    if (event.checked == true) {
      this.ChktbltransmitionTypeManual=true;
      this.ChktbltransmitionTypeAutomatic=false;
      this.vehiclerSearchForm.controls.tbltransmitionTypeID.setValue(2);
    }
    if (event.checked == false) {
      this.ChktbltransmitionTypeManual=false;
      this.vehiclerSearchForm.controls.tbltransmitionTypeID.setValue(0);
    }
  }
  transmitionCheckAutomatic(event) {
    if (event.checked == true) {
      this.ChktbltransmitionTypeManual=false;
      this.ChktbltransmitionTypeAutomatic=true;
      this.vehiclerSearchForm.controls.tbltransmitionTypeID.setValue(1);
    }
    if (event.checked == false) {
      this.ChktbltransmitionTypeAutomatic=false;
      this.vehiclerSearchForm.controls.tbltransmitionTypeID.setValue(0);
    }
  }
  getAllFeature() {
    this.commonMasterService.FeatureMasterlist().subscribe(res => {
      if (res.length > 0) {
        this.featureMasterArray = res;
      }
    });
  }

  SelectedTypes(types) {
    this.vehiclerSearchForm.controls.featureId.setValue(
      types.value
    );
  }
  searchlist(){  
    const ss=this.vehiclerSearchForm.getRawValue() as HomeSearch;
    if(ss.featureId==null){
      ss.featureId=[];
    }
    if(ss.tblregionId ==null){
      ss.tblregionId=0;
    }
    ss.tblregionId=this.tblregionId;
    if(this.ChktbltransmitionTypeManual ==false && this.ChktbltransmitionTypeAutomatic==false){
      this.vehiclerSearchForm.controls.tbltransmitionTypeID.setValue(0);
    }

    if(ss.tblVehicleId ==null ||ss.tblVehicleId==undefined){
      ss.tblVehicleId=0;
    }
    if(ss.tblMakeId ==null ||ss.tblMakeId==undefined){
      ss.tblMakeId=0;
    }
    if(ss.tblModelId ==null ||ss.tblModelId==undefined){
      ss.tblModelId=0;
    }
    if(ss.tblregionId ==null ||ss.tblregionId==undefined){
      ss.tblregionId=0;
    }
    if(ss.tblFuelTypeID ==null ||ss.tblFuelTypeID==undefined){
      ss.tblFuelTypeID=0;
    }
    if(ss.tbltransmitionTypeID ==null ||ss.tbltransmitionTypeID==undefined){
      ss.tbltransmitionTypeID=0;
    }
    if(ss.price.length>0){
      ss.price[0]= ss.price[0].toString();
      ss.price[1]= ss.price[1].toString()
    }
    if(ss.year.length>0){
      ss.year[0]= ss.year[0].toString();
      ss.year[1]= ss.year[1].toString()
    }
    this.searchAllService.SearchHome(ss).subscribe(res=>{
     //  this.objvehicleRegistrationService = res.filter(x=>x.isShow==1);
      this.searchAllService.HomePageSearchResult.next(res);
      this.router.navigate(['listing'])
    });
  }
  onImageClick(){
    this.router.navigate(['/default']);
  }
  
  selectedOrgTypes(a, b) {
    // tslint:disable-next-line:no-unused-expression
    return a === b;
  }
  yearChange(event:any){
    this.year=event.values[0] +"-"+ event.values[1];
  }
  priceChange(event:any){
    this.price=event.values[0] +"-"+ event.values[1];
  }
  setRegionValue(id:number){
  this.tblregionId=id;
  }
  btnsubmit() {



    const vehicle_model= this.vehicle_model;
    const Name=this.name;
    const Email=this.email;
    const Mobile=this.mobile;
    const Link= window.location.href;
    const body='<html><head><title></title></head><body style="cursor: auto;">Hi,<br /><br />my name is '+Name +', interested in learning more about this '+vehicle_model+'<br />&nbsp;<table border="1" cellpadding="1" cellspacing="1" style="width:500px"><tbody><tr><td style="width: 123px;">Email</td><td style="width: 365px;">'+Email+'</td></tr><tr><td style="width: 123px;">Name&nbsp;</td><td style="width: 365px;">'+Name+'</td></tr><tr><td style="width: 123px;">vehicle_model</td><td style="width: 365px;">'+Link+'</td></tr><tr><td style="width: 123px;">Mobile</td><td style="width: 365px;">'+Mobile+'</td></tr></tbody></table><br /><br />Regards<br /><span style="color:#FF0000">Team</span></body></html>'
    this.interestedEmailModel.body=body;
    if(Email!="" && Email !=null &&Email!=undefined&&Name!="" && Name !=null &&Name!=undefined&& Mobile!="" && Mobile !=null &&Mobile!=undefined  ){
      if(this.ValidateEmail(Email)==true){
        this.commonMasterService.sendInterestedMail(this.interestedEmailModel).subscribe(res=>{
          if(res=="Email sent."){
            this.name="";
            this.vehicle_model="";
            this.email="";
            this.mobile="";
            this.uiService.showSuccess(`Your interest has been sent`);
          }
        });
      }else{
        this.uiService.showError("You have entered an invalid email address!")
      }

    } else{
      this.uiService.showError("E-mail,Name,Mobile should not be empty")
    }
 

  }
  btnCancel() {
    this.name="";
    this.vehicle_model="";
    this.email="";
    this.mobile="";
  }

   intToEnglish(number) {

    var NS = [
      { value: 10000000, str: "Crore" },
      { value: 100000, str: "Lakh" },
      { value: 1000, str: "Thousand" },
      { value: 100, str: "Hundred" },
      { value: 90, str: "Ninety" },
      { value: 80, str: "Eighty" },
      { value: 70, str: "Seventy" },
      { value: 60, str: "Sixty" },
      { value: 50, str: "Fifty" },
      { value: 40, str: "Forty" },
      { value: 30, str: "Thirty" },
      { value: 20, str: "Twenty" },
      { value: 19, str: "Nineteen" },
      { value: 18, str: "Eighteen" },
      { value: 17, str: "Seventeen" },
      { value: 16, str: "Sixteen" },
      { value: 15, str: "Fifteen" },
      { value: 14, str: "Fourteen" },
      { value: 13, str: "Thirteen" },
      { value: 12, str: "Twelve" },
      { value: 11, str: "Eleven" },
      { value: 10, str: "Ten" },
      { value: 9, str: "Nine" },
      { value: 8, str: "Eight" },
      { value: 7, str: "Seven" },
      { value: 6, str: "Six" },
      { value: 5, str: "Five" },
      { value: 4, str: "Four" },
      { value: 3, str: "Three" },
      { value: 2, str: "Two" },
      { value: 1, str: "One" }
    ];
  
    var result = '';
    for (var n of NS) {
      if (number >= n.value) {
        if (number <= 99) {
          result += n.str;
          number -= n.value;
          if (number > 0) result += ' ';
        } else {
          var t = Math.floor(number / n.value);
          // console.log(t);
          var d = number % n.value;
          if (d > 0) {
            return this.intToEnglish(t) + ' ' + n.str + ' ' + this.intToEnglish(d);
          } else {
            return this.intToEnglish(t) + ' ' + n.str;
          }
  
        }
      }
    }
    return result;
  }
  ValidateEmail(mail) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
      return (true)
    }
    return (false)
  }
  

}
