import { Component, Inject, OnInit, ViewChild, AfterViewInit, ElementRef, HostListener, Renderer2, ViewEncapsulation, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import $ from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonMasterService } from './services/common.service';
import { UiService } from './core/services/ui.services';
import { LoginService } from './services/Login.service';
import { UserMaster } from './models/Users-master';
export interface DialogData {
  animal: string;
  name: string;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent  implements OnInit {
  screenWidth: number;
  loginStatus="Login";
  constructor(
    private loginService : LoginService,
    public router: Router,
    private route:ActivatedRoute,
    public logindialog: MatDialog,
    public registerdialog: MatDialog,
  ) { 
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
    };
    this.loginService.subject.subscribe(res=>{
      this.loginStatus=res
    });
    
    
  }

  onImageClick(){
    this.router.navigate(['/default']);
  }
 
  openLoginDialog(): void {
    if(sessionStorage.getItem('token')==null  || sessionStorage.getItem('token')=="" ||  sessionStorage.getItem('token')==undefined){
      const dialogRef = this.logindialog.open(LoginComponent, {
        width: '35%'
      });
  
      // dialogRef.afterClosed().subscribe(result => {
      //   console.log('The dialog was closed');
      // });
    }
    if(sessionStorage.getItem('token')!=undefined  && sessionStorage.getItem('token')!="" && sessionStorage.getItem('token')!=null ){
      sessionStorage.removeItem('token');
      this.loginStatus="Login";
      this.router.navigate(['/default']);
     
      // this.router.navigateByUrl('/default', { skipLocationChange: true }).then(() => {
      //   this.router.navigate(['/default']);
    //});

    }
  
  }
  
  openRegisterDialog(): void {
    const dialogRef = this.registerdialog.open(RegisterComponent, {
      width: '35%'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  hidemenu(stat){
    if(stat == true){

      $("#toolbar").css("margin-top", "-40px");
      if($(".reportingtab .tablecontainer")){
        $(".reportingtab .tablecontainer").addClass("tablecontainer-reportingtab-withouttoolbar").removeClass("tablecontainer");
      }
      else if($(".tablecontainer")){
        $(".tablecontainer").addClass("tablecontainer-withouttoolbar").removeClass("tablecontainer");
      }
      
      
      
    }
    else{ 
      $("#toolbar").css("margin-top", "0px");
      $(".tablecontainer-withouttoolbar").addClass("tablecontainer").removeClass("tablecontainer-withouttoolbar");
      $(".reportingtab .tablecontainer-reportingtab-withouttoolbar").addClass("tablecontainer").removeClass("tablecontainer-reportingtab-withouttoolbar");
    }

}
ngOnInit(){ 

 
  if(sessionStorage.getItem('token')!=undefined  && sessionStorage.getItem('token')!="" && sessionStorage.getItem('token')!=null ){
    this.loginStatus="Logout";
    this.router.navigate(['/region']);

  }
  if(sessionStorage.getItem('token')==null  || sessionStorage.getItem('token')=="" ||  sessionStorage.getItem('token')==undefined){
    this.loginStatus="Login";
  }
}

   
}

@Component({
  selector: 'app-appcomponent',
  templateUrl: 'login.component.html',  
  styleUrls: ['./app.component.css']
})

export class LoginComponent {
  constructor(
    public dialogRef: MatDialogRef<LoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private loginService : LoginService,private router: Router ,
    private formBuilder: FormBuilder,  private uiService: UiService) {
      this.initLoginmasterForm(new UserMaster())
    }
    loginForm: FormGroup;
    userMaster=new UserMaster();
  onNoClick(): void {
    this.dialogRef.close();
  }
  initLoginmasterForm(m: UserMaster) {
    this.loginForm = this.formBuilder.group({
      username:  [m.username == null ? '' : m.username, Validators.required],
      password: [m.password == null ? '' : m.password, Validators.required],
      
    });
  }
  submit(){
    this.userMaster=this.loginForm.getRawValue();
    debugger
    this.loginService.Login(this.userMaster).subscribe(res=>{
      if(res.token !="" && res.token!=null && res.token!=undefined){
        sessionStorage.setItem("token",res.token );
        this.router.navigate(['/region']);
        this.loginService.subject.next("LogOut")
        this.dialogRef.close();
      }else{
        this.uiService.showError("Invalid user name or password")
      }

    },(err) => {this.uiService.showError("Invalid user name or password")});
    

  }
  Cancel(){
    this.initLoginmasterForm(new UserMaster());
  }

}

@Component({
  selector: 'app-appcomponent',
  templateUrl: 'register.component.html',  
  styleUrls: ['./app.component.css']
})

export class RegisterComponent {
 
  constructor(
    public dialogRef: MatDialogRef<RegisterComponent>, private uiService: UiService,private loginService : LoginService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,private formBuilder: FormBuilder, 
    private commonMasterService: CommonMasterService) {
      this.initMakemasterForm(new Register() );
    }
    

    registerForm: FormGroup;
  onNoClick(): void {
    this.dialogRef.close();
  }
  ValidateEmail(mail) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
      return (true)
    }
    return (false)
  }
  OnInterest(){
    let emailModel=new EmailModel();
    const frmFullName=this.registerForm.controls.frmFullName.value;
    const frmAddress=this.registerForm.controls.frmAddress.value;
    const frmMobile=this.registerForm.controls.frmMobile.value;
    const email= this.registerForm.controls.email.value;
    if(frmFullName!="" && frmFullName!=null && frmFullName!=undefined &&frmAddress!="" && frmAddress!=null && frmAddress!=undefined && frmMobile!="" && frmMobile!=null && frmMobile!=undefined && email!="" && email!=null && email!=undefined )
    {
      const body=
      emailModel.body="<html><head><title></title></head><body style='cursor: auto;'>Hi&nbsp;<br />&nbsp;<table border='1' cellpadding='1' cellspacing='1' style='width:674px'><tbody>"+
      "<tr><td style='width: 135px;'>Full Name</td><td style='width: 526px;'>"+frmFullName+"</td></tr><tr><td style='width: 135px;'>Email</td><td style='width: 526px;'>"+email+"</td></tr><tr><td style='width: 135px;'>Phone Number</td>"+
      "<td style='width: 526px;'>"+frmMobile+"</td></tr><tr><td style='width: 135px;'>Address</td><td style='width: 526px;'>"+frmAddress+"</td></tr></tbody></table><br /><br />Regards<br />Team</body></html>";
     if(this.ValidateEmail(email)==true){
      this.commonMasterService.sellMycarInterestedMail(emailModel).subscribe(res=>{
        if(res=="Email sent."){
          this.registerForm.get('frmFullName').setValue("");
          this.registerForm.get('frmAddress').setValue("");
          this.registerForm.get('frmMobile').setValue("");
          this.registerForm.get('email').setValue("");
          this.uiService.showSuccess(`Your interest has been sent, we will contact you soon`);
          this.initMakemasterForm(new Register() );
        }
      });
     }else{
      this.uiService.showError("You have entered an invalid email address!")
     }

    } else{
      this.uiService.showError("Full Name, Address, E-mail, Mobile Should not be empty")
    }


  }
  OnCancel(){
    this.initMakemasterForm(new Register() );
  }
  initMakemasterForm(m: Register) {
    this.registerForm = this.formBuilder.group({
      frmFullName:  [m.fullName == null ? '' : m.fullName, Validators.required],
      frmAddress: [m.address == null ? '' : m.address, Validators.required],
      frmMobile:  [m.mobile == null ? '' : m.mobile, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      email: [m.email == null ? '' : m.email, [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    });
  }

  

}
export class Register {
  fullName='';
  address='';
  mobile='';
  email='';

}
export class EmailModel {
  body='';
}
