export class HomeSearch{
    tblVehicleId=0;
    tblMakeId=0;
    tblModelId=0;
    tblregionId:number;
    tblFuelTypeID=0;
    year: any []=[];
    price: any []=[];
    featureId: any []=[];
    tbltransmitionTypeID=0
    
    
}