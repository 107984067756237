import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VehicleMaster } from '../models/vehicle-master';


@Injectable({
  providedIn: 'root'
})
export class VehicleMasterService {

  constructor(private httpClient: HttpClient) {}




  list(): Observable<any> {
    return this.httpClient.get<VehicleMaster>(`api/VehicleMaster/List`);
  }

  listBuId(id: number): Observable<any> {
    return this.httpClient.get<VehicleMaster>(`api/VehicleMaster/select/`+id);
  }
  create(vehicleMaster: VehicleMaster): Observable<any> {
    return this.httpClient.post<VehicleMaster>(`api/VehicleMaster`, vehicleMaster);
  }
  update(vehicleMaster: VehicleMaster): Observable<any> {
    return this.httpClient.put<VehicleMaster>(`api/vehicleMaster/`+vehicleMaster.id , vehicleMaster);
  }

  delete(vehicleMaster: VehicleMaster): Observable<any> {
    return this.httpClient.delete(`api/VehicleMaster/` + vehicleMaster.id );
  }

}
