export class VehicleRegistarion {
    id=0;
    tblVehicleId: string;
    tblMakeId: number;
    tblModelId: number;
    tblregionId: number;
    tblFuelTypeID: number;
    tbltransmitionTypeID: number;
    year: Date;
    price: number;
    kilomeators: string;
    tblOwnerTypeId: number;
    isActive=1;
    featureId= new Array<FeatureIdArray>() ;
    UploadImageList= new Array<UploadImage>() ;
    isFancy=0;
    isFancyVehicleNumber: string;

}

export class  FeatureIdArray {
    tblFeatureId?= 0;
    featureType?= '';
    tblVehicleRegistrationId?= 0;
}
export class  UploadImage {
    id?=0;
    hostFilePath?='';
    fullFilePath?='';
    fileName?='';
    tblVehicleRegistrationId?=0;
    isActive?=0;
}





// tslint:disable-next-line:class-name
export class photoUrl {
    Id?: number;
    FileName?: string;
}


