// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

  // local host 
   //export const environment = { production: false, host: 'https://localhost:44310/' };
// local host  https
 // export const environment = { production: false, host: 'https://localhost:5001/' };
  
 // aws with http
 // export const environment = { production: false, host: 'http://3.95.158.63:8081/' };
// aws with https
   export const environment = { production: true, host: 'https://api.fancysecond.in/' };
 
 // export const environment = { production: true, host: 'http://localhost:8009/' };
/* http://3.95.158.63:8081/
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
