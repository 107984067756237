import { VehicleMasterService } from './../services/vehicle-master.service';
import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { Observable, of } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
// tslint:disable-next-line:max-line-length
import { Inject, OnInit, ViewChild, AfterViewInit, ElementRef, HostListener, Renderer2, ViewEncapsulation, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatTableModule, getMatFormFieldPlaceholderConflictError, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS, MatDatepicker, MatMenuTrigger } from '@angular/material';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import * as $ from 'jquery';
import { SliderService } from '../slider.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { VehicleRegistarion, photoUrl, FeatureIdArray, UploadImage } from '../models/vehicle-registration';
import { MakeMasterService } from '../services/make-master.service';
import { UiService } from '../core/services/ui.services';
import { RegionMaster } from '../models/region-master';
import { RegionMasterService } from '../services/region-master.service';
import { VehicleMaster } from '../models/vehicle-master';
import { ModelMasterService } from '../services/model-master.service';
import { MakeMaster } from '../models/make-master';
import { ModelMaster } from '../models/model-master';
import { CommonMasterService } from '../services/common.service';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { FeatureMasterService } from '../services/feature.service';
import { FeatureMaster } from '../models/feature-master';
import { VehicleRegistrationService } from '../services/vehicle-registration.service';
// const moment = _rollupMoment || _moment;
import moment from 'moment';

export interface Category {
  name: string;
}
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY'
  },
};
@Component({
  selector: 'app-vehicleregistration',
  templateUrl: './vehicleregistration.component.html',
  styleUrls: ['./vehicleregistration.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class VehicleregistrationComponent implements OnInit {

  constructor(private vehicleMasterService: VehicleMasterService,
    private makeMasterService: MakeMasterService,
    private formBuilder: FormBuilder,
    private uiService: UiService,
    private regionMasterService: RegionMasterService,
    private modelMasterService: ModelMasterService,
    private commonMasterService: CommonMasterService,
    private featureMasterService: FeatureMasterService,
    private vehicleRegistrationService: VehicleRegistrationService,
    private cd: ChangeDetectorRef) {
    this.initVehicleRegistration(new VehicleRegistarion());
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
    };
  }
  date = new FormControl(moment());


  // ==================================================
  vehicleRegistarion = new VehicleRegistarion();
  vehicleregistrationForm: FormGroup;
  clsRegionMasterArray = Array<RegionMaster>();
  clsVehicleMasterArray = Array<VehicleMaster>();
  clsMakeMasterArray = new Array<MakeMaster>();
  clsModelMasterArray = new Array<ModelMaster>();
  transmitionTypeArray = new Array<any>();
  fuleTypeArray = new Array<any>();
  OwnerTypeArray = new Array<any>();
  featureMasterArray = new Array<FeatureMaster>();
  screenWidth: number;
  toppingsControl = new FormControl([]);
  selectedFiles: FileList;
  urls = new Array<photoUrl>();
  uploadImage = new Array<UploadImage>();
  frmData = new FormData();
  val: number[] = [0, 100];
  // tslint:disable-next-line:max-line-length
  displayedColumns: string[] = ['Id', 'regionName', 'VehicleName', 'MakeName', 'ModelName', 'FuelType', 'driveMode', 'Price', 'Year', 'EditDelete'];
  dataSource: any;

  // tslint:disable-next-line:member-ordering
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  @ViewChild(MatMenuTrigger, { static: true }) clickHoverMenuTrigger: MatMenuTrigger;
  onToppingRemoved(topping: string) {
    // const toppings = this.toppingsControl.value as string[];
    // this.removeFirst(toppings, topping);
    // this.toppingsControl.setValue(toppings); // To trigger change detection
  }
  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }
  ngOnInit() {
    this.getAllRegionMaster();
    this.getAllVehicleMaster();
    this.getDriveMode();
    this.getFuleType();
    this.getOwnerType();
    this.getAllFeature();
    this.getAllregistration();
    //  this.vehicleregistrationForm.controls.isActive.setValue(true);
    // this.dataSource = new MatTableDataSource(ELEMENT_DATA);
    // this.dataSource.paginator = this.paginator;

    this.vehicleregistrationForm.controls.isFancyVehicleNumber.disable();
    this.vehicleregistrationForm.controls.isFancy.setValue(0);
  }
  initVehicleRegistration(m: VehicleRegistarion) {
    this.vehicleregistrationForm = this.formBuilder.group({
      id: [m.id],
      tblVehicleId: [m.tblVehicleId, Validators.required],
      tblMakeId: [m.tblMakeId, Validators.required],
      tblModelId: [m.tblModelId, Validators.required],
      tblregionId: [m.tblregionId, Validators.required],
      tblFuelTypeID: [m.tblFuelTypeID, Validators.required],
      tbltransmitionTypeID: [m.tbltransmitionTypeID, Validators.required],
      year: [m.year, Validators.required],
      price: [m.price, Validators.required],
      kilomeators: [m.kilomeators, Validators.required],
      tblOwnerTypeId: [m.tblOwnerTypeId, Validators.required],
      isActive: [m.isActive],
      featureId: [m.featureId, Validators.required],
      isFancy: [m.isFancy],
      isFancyVehicleNumber: [m.isFancyVehicleNumber]

    });
  }
  // ===========================================
  //
  // ============================================
  getAllregistration() {
    this.vehicleRegistrationService.list().subscribe(res => {
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
    });

  }
  checkedEvent(event: any) {
    if (event.checked == true) {
      this.vehicleregistrationForm.controls.isFancyVehicleNumber.setValue('');
      this.vehicleregistrationForm.controls.isFancyVehicleNumber.enable();
      this.vehicleregistrationForm.controls.isFancy.setValue(1);
    }
    if (event.checked == false) {
      this.vehicleregistrationForm.controls.isFancyVehicleNumber.setValue('');
      this.vehicleregistrationForm.controls.isFancyVehicleNumber.disable();
      this.vehicleregistrationForm.controls.isFancy.setValue(0);
    }

  }
  // ============================================
  // region
  // ============================================
  getAllRegionMaster() {
    this.regionMasterService.list().subscribe(res => {
      this.clsRegionMasterArray = res;
    });
  }
  // ===========================================
  //
  // ===========================================
  getAllVehicleMaster() {
    this.vehicleMasterService.list().subscribe(res => {
      this.clsVehicleMasterArray = res;
    });
  }
  // ===========================================
  //
  // ===========================================
  async getAllMakeMaster(value: any) {
    const query = value;
    this.modelMasterService.listByVehicleMasterId(query).subscribe(res => {
      this.clsMakeMasterArray = new Array<MakeMaster>();
      this.clsModelMasterArray = new Array<ModelMaster>();
      this.clsMakeMasterArray = res;
    });
  }
  // ============================================
  //
  // ============================================
  async getAllModels(makeId: any) {
    this.clsModelMasterArray = new Array<ModelMaster>();
    this.modelMasterService.listModelByMakeId(makeId).subscribe(res => {
      this.clsModelMasterArray = res;
    });
  }
  // ====================================
  //
  // ====================================
  async onvehicleMasterChange(event: any) {
    const value = event.value;
    this.vehicleregistrationForm.get('tblVehicleId').setValue(value);
    this.vehicleregistrationForm.get('tblMakeId').setValue(null);
    this.vehicleregistrationForm.get('tblModelId').setValue(null);
    await this.getAllMakeMaster(value);
  }
  // ======================================
  //
  // ======================================
  async onvehicleMakeChange(event: any) {
    const value = event.value;
    await this.getAllModels(value);
    this.vehicleregistrationForm.get('tblMakeId').setValue(value);
    this.vehicleregistrationForm.get('tblModelId').setValue(null);
  }
  // =====================================
  // get drive mode
  // =====================================
  getDriveMode() {
    this.commonMasterService.driveModeList().subscribe(res => {

      this.transmitionTypeArray = res;
    });
  }
  // =====================================
  // get fule mode
  // =====================================
  getFuleType() {
    this.commonMasterService.fuleTypeList().subscribe(res => {
      this.fuleTypeArray = res;
    });
  }

  getOwnerType() {
    this.commonMasterService.ownertypeList().subscribe(res => {
      this.OwnerTypeArray = res;
    });
  }
  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    // this.date.setValue(ctrlValue);
    this.vehicleregistrationForm.controls.year.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.vehicleregistrationForm.controls.year.setValue(ctrlValue);
    // this.date.setValue(ctrlValue);
    datepicker.close();
  }
  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      this.frmData = new FormData();
      this.selectedFiles = event.target.files;
    }
  }
  removeAt(value: any, index) {
    this.uiService
      .showConfirmationBox('Delete', 'Are you sure you want to delete ?...')
      .subscribe(result => {
        if (result) {
          this.vehicleRegistrationService.deletePhotoByid(value.id).subscribe(res => {
            if (res > 0) {
              this.clickHoverMenuTrigger.closeMenu();
              this.uiService.showSuccess(`${value.fileName} was deleted`);
              this.uploadImage.splice(index, 1);
            }
          });
        }
      });
  }

  Delete(element: any) {
    this.uiService
      .showConfirmationBox('Delete', 'Are you sure you want to delete ?...')
      .subscribe(result => {
        if (result) {
          this.vehicleRegistrationService.DeleteVehicleRegistrationById(element.id).subscribe(res => {
            if (res > 0) {

              this.uiService.showSuccess(`${element.modelName} was deleted`);
              this.Cancel();
              this.getAllregistration();
            }
          });
        }
      });
  }
  getAllFeature() {
    this.featureMasterService.list().subscribe(res => {
      if (res.length > 0) {
        this.featureMasterArray = res;
      }
    });

  }
  uploadFiles(event) {
    const formModel = this.selectedFiles;
    // console.log(formModel)
    this.commonMasterService.uploadFiles(formModel).subscribe(res => {
      if (res.data.length > 0) {
      }
    });
  }
  SelectedTypes(types) {
    this.vehicleregistrationForm.controls.featureId.setValue(
      types.value
    );
  }

  selectedOrgTypes(a, b) {
    // tslint:disable-next-line:no-unused-expression
    return a === b;
  }


  save() {
    const values = this.vehicleregistrationForm.getRawValue() as VehicleRegistarion;
    const featureIdArray = values.featureId as [];
    const featureIdClassArray = new Array<FeatureIdArray>();

    if (featureIdArray.length > 0) {

      for (let index = 0; index < featureIdArray.length; index++) {
        featureIdClassArray.push({
          tblFeatureId: featureIdArray[index]
        });
      }
    }
    values.featureId = featureIdClassArray;
    if (values.id == 0 || values.id == null) {
      this.vehicleRegistrationService.create(values).subscribe(res => {

        if (res > 0) {
          this.uiService.showSuccess('Saved Successfully');
          if (this.selectedFiles != undefined) {
            this.vehicleRegistrationService.PhotoUplaod(this.selectedFiles, res).subscribe(res1 => {
            });
          }

          this.getAllregistration();
          this.Cancel();
        }
      });
    }
    if (values.id > 0) {

      this.vehicleRegistrationService.update(values).subscribe(res => {
        if (res > 0) {
          this.uiService.showSuccess('updated Successfully');
          if (this.selectedFiles != undefined) {
            this.vehicleRegistrationService.PhotoUplaod(this.selectedFiles, res).subscribe(res1 => {
            });
          }
          this.getAllregistration();
          this.Cancel();
        }
      });
    }




  }
  isFancyValidation() {

    if (this.vehicleregistrationForm.controls.isFancy.value == true) {
      if (this.vehicleregistrationForm.controls.isFancyVehicleNumber.value != undefined && this.vehicleregistrationForm.controls.isFancyVehicleNumber.value != "") {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }

  }
  validateFileUpload() {

    if (this.selectedFiles !== undefined) {
      if (this.selectedFiles.length > 0) {
        return true;

      } else {
        this.uiService.showWarning('Please upload atleast 1 images');
        return false;
      }
    } else {
      if (this.uploadImage.length > 0) {
        return true;
      }
      else {
        this.uiService.showWarning('Please upload atleast 1 images');
        return false;
      }

    }

  }
  Cancel() {
    this.initVehicleRegistration(new VehicleRegistarion());
    this.vehicleRegistarion = new VehicleRegistarion();
    this.uploadImage = new Array<UploadImage>();
    this.uploadImage = [] = [];
    this.vehicleRegistarion.featureId = [];
    this.getAllregistration();
    this.vehicleregistrationForm.controls.isFancyVehicleNumber.disable();
    this.vehicleregistrationForm.controls.isFancy.setValue(0);
  }
  async Edit(element) {
    this.uploadImage = new Array<UploadImage>();
    setTimeout(() => {
      this.getAllMakeMaster(element.tblVehicleId);
      this.getAllModels(element.tblMakeId);
    }, 200);
    setTimeout(() => {

      this.vehicleRegistarion.id = element.id;
      this.vehicleRegistarion.kilomeators = element.kilomeators;
      this.vehicleRegistarion.price = element.price;
      this.vehicleRegistarion.tbltransmitionTypeID = element.tbltransmitionTypeID;
      this.vehicleRegistarion.tblFuelTypeID = element.tblFuelTypeID;
      this.vehicleRegistarion.tblVehicleId = element.tblVehicleId;
      this.vehicleRegistarion.tblMakeId = element.tblMakeId;
      this.vehicleRegistarion.tblModelId = element.tblModelId;
      this.vehicleRegistarion.tblOwnerTypeId = element.tblOwnerTypeId;
      this.vehicleRegistarion.tblregionId = element.tblregionId;
      this.vehicleRegistarion.isFancy = element.isFancy;
      this.vehicleRegistarion.isFancyVehicleNumber = element.isFancyVehicleNumber;
      //const d = new Date(element.year).getFullYear(); //  new Date('1-1-' + element.year);   
      this.vehicleRegistarion.year = new Date(element.year);;
      if (element.featureId.length > 0) {
        const featureArrayId = [];
        for (let index = 0; index < element.featureId.length; index++) {
          featureArrayId.push(element.featureId[index].tblFeatureId);
        }
        this.vehicleregistrationForm.controls.featureId.setValue(featureArrayId);
        this.vehicleRegistarion.featureId = featureArrayId;
      }
      this.uploadImage = [] = [];
      if (element.uploadImageList.length > 0) {
        for (let index = 0; index < element.uploadImageList.length; index++) {
          this.uploadImage.push({
            id: element.uploadImageList[index].id,
            hostFilePath: element.uploadImageList[index].hostFilePath,
            fullFilePath: element.uploadImageList[index].fullFilePath,
            fileName: element.uploadImageList[index].fileName,
            tblVehicleRegistrationId: element.uploadImageList[index].tblVehicleRegistrationId,
          });
        }
      }

      this.initVehicleRegistration(this.vehicleRegistarion);
      if (this.vehicleRegistarion.isFancy == 0) {
        this.vehicleregistrationForm.controls.isFancyVehicleNumber.setValue('');
        this.vehicleregistrationForm.controls.isFancyVehicleNumber.disable();

      }
      if (this.vehicleRegistarion.isFancy == 1) {
        this.vehicleregistrationForm.controls.isFancyVehicleNumber.enable();
      }
    }, 500);
  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }
}


