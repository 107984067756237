import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UiService } from '../core/services/ui.services';
import { UserMaster } from '../models/Users-master';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
 
    public subject: BehaviorSubject<any> = new BehaviorSubject<any>("Login");
   
    constructor( private httpClient: HttpClient, private uiService: UiService) { 
       
    }
    Login(userMaster: UserMaster): Observable<any> {
        return this.httpClient.post<UserMaster>(`api/Users/authenticate`, userMaster);
      }
}
