import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';


@Injectable()
export class SliderService {

  sliderValuesSubject: Subject<number> = new Subject();
  sliderValues: Observable<number> = this.sliderValuesSubject.asObservable();

  resetSlider() {
    this.sliderValuesSubject.next(0);
  }

}