import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }

  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (!environment.production) {
      if (response instanceof HttpErrorResponse) {
        if (response.status === 401 && !this.router.url.startsWith('/?')) {
          this.authService.logout();
          this.router.navigate(['/'], {
            queryParams: { returnUrl: this.router.routerState.snapshot.url },
          });
        }
      }
      throw response;
    }

    if (response instanceof HttpErrorResponse) {
      
      if (response.status === 401) {
        this.authService.logout();
        this.router.navigate(['/'], {
          queryParams: { returnUrl: this.router.routerState.snapshot.url },
        });
      }

      const applicationError = response.headers.get('Application-Error');
      if (applicationError) {
        // this.uiService.showError(applicationError);
        // this.loadingBar.complete();
        return;
      }
      const serverError = response.error;
      let modelStateErrors = '';
      if (serverError && typeof serverError === 'object') {
        for (const key in serverError) {
          if (serverError[key]) {
            modelStateErrors += serverError[key] + '\n';
          }
        }
      }
      // this.loadingBar.complete();
      // this.uiService.showError(modelStateErrors || serverError || 'Server error');
    }

    throw response;
  }
}
