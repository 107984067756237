import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ListingComponent } from './listing/listing.component';
import { MakeComponent } from './make/make.component';
import { VehiclemasterComponent } from './vehiclemaster/vehiclemaster.component';
import { VehicleregistrationComponent } from './vehicleregistration/vehicleregistration.component';
import { DetailsComponent } from './details/details.component';
import { ModelMasterComponent } from './model-master/model-master.component';
import { RegionComponent } from './region/region.component';
import { FeatureComponent } from './feature/feature.component';

const routes: Routes = [
  {
    path: 'VehicleRegistration',
    component: VehicleregistrationComponent,
  },
  {
    path: '',
    redirectTo: "/default",
    pathMatch: 'full'
  },
  {
    path: 'default',
    component: HomeComponent,
  },
  {
    path: 'listing',
    component: ListingComponent,
  },
  {
    path: 'brand',
    component: MakeComponent,
  },
  {
    path: 'vehicletype',
    component: VehiclemasterComponent,
  },
  {
    path: 'details',
    component: DetailsComponent,
  },
  {
    path: 'model',
    component: ModelMasterComponent,
  },
  {
    path: 'region',
    component: RegionComponent,
  },
  {
    path: 'feature',
    component: FeatureComponent,
  }
];



@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
