import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UiService } from '../core/services/ui.services';
import { VehicleRegistarion } from '../models/vehicle-registration';

@Injectable({
  providedIn: 'root'
})
export class VehicleRegistrationService {

  constructor(private httpClient: HttpClient, private uiService: UiService) {}

  create(vehicleRegistarion: VehicleRegistarion): Observable<any> {
    
    return this.httpClient.post<VehicleRegistarion>(`api/VehicleRegistration`, vehicleRegistarion );
  }
  update(vehicleRegistarion: VehicleRegistarion): Observable<any> {
    
    return this.httpClient.post<VehicleRegistarion>(`api/VehicleRegistration/Update`, vehicleRegistarion );
  }

  PhotoUplaod(fileList: FileList, id: any): Observable<any> {
    
    if (fileList === undefined || fileList === null || fileList.length === 0) {
      this.uiService.showError('No Files Detected');
      return;
    }

    const formData = new FormData();
    Array.from(fileList).forEach((file: File) => {
      // 5242880 is 5 mb in binary bytes
      if (file.size > 5242880) {
        this.uiService.showError('Size limit');
      }
      formData.append('files', file);
    });
    return this.httpClient.post<any>(`api/VehicleRegistration/PhotoUplaod/`+id, formData, {
      headers: new HttpHeaders().set('Accept', 'application/json'),
    });
  }

  
  list(): Observable<any[]> {
    return this.httpClient.get<VehicleRegistarion[]>(`api/vehicleRegistration/List`);
  }

  listById(Id: any): Observable<any[]> {
    const query = {query :   Id };
    return this.httpClient.post<any[]>(`vehicleRegistration/selectById`, query);
  }

 
  deletePhotoByid(id: any): Observable<any> {
    return this.httpClient.delete(`api/VehicleRegistration/DeleteUploadPhotoById/` + id );
  }

  DeleteVehicleRegistrationById(id: any): Observable<any> {
    return this.httpClient.delete(`api/VehicleRegistration/DeleteVehicleRegistrationById/` + id );
  }
}
