import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { UiService } from '../core/services/ui.services';
import { MakeMaster } from '../models/make-master';
import { ModelMaster } from '../models/model-master';
import { RegionMaster } from '../models/region-master';
import { HomeSearch } from '../models/search-home';
import { VehicleMaster } from '../models/vehicle-master';
import { CommonMasterService } from '../services/common.service';
import { FeatureMasterService } from '../services/feature.service';
import { MakeMasterService } from '../services/make-master.service';
import { ModelMasterService } from '../services/model-master.service';
import { RegionMasterService } from '../services/region-master.service';
import { SearchAllService } from '../services/searchAll.service';
import { VehicleMasterService } from '../services/vehicle-master.service';
import { VehicleRegistrationService } from '../services/vehicle-registration.service';
import { SliderService } from '../slider.service';
import * as data from "../../assets/yearandprice.json"
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  objvehicleRegistrationService :any[]=[];
  vehiclerSearchForm: FormGroup;
  clsRegionMasterArray = Array<RegionMaster>();
  clsVehicleMasterArray = Array<VehicleMaster>();
  clsMakeMasterArray = new Array<MakeMaster>();
  clsMakeMasterBrandArray = new Array<MakeMaster>();
  clsModelMasterArray = new Array<ModelMaster>();
  price="5000"+"-"+"2000";
  year="1800"+"-"+"1900";
  minYear:any;
  maxYear:any;
  minPrice:any;
  maxPrice:any;
  product: any =(data as any ).default;
  slides = [
    {img: "assets/images/carimg.png"},
    {img: "assets/images/carimg2.png"},
    {img: "assets/images/carimg.png"},
    {img: "assets/images/carimg2.png"},
    {img: "assets/images/carimg.png"},
    {img: "assets/images/carimg2.png"},
  ];
  slideConfig = {
    slidesToShow: 5, slidesToScroll: 1, responsive: [
    {
    breakpoint: 1024,
    settings: {
    slidesToShow: 3,
    initialSlide: 1,
    arrows: true,
    dots: true
    }
    },
    {
    breakpoint: 600,
    settings: {
    slidesToShow: 2
    }
    },
    {
    breakpoint: 480,
    settings: {
    slidesToShow: 1
    }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
    ]
    };
YearAndPrice: number[] = [0,100];
 

next() {
  this.YearAndPrice[0]++;

}

 
  
  slickInit(e) {
    console.log('slick initialized');
  }
  
  breakpoint(e) {
    console.log('breakpoint');
  }
  
  afterChange(e) {
    console.log('afterChange');
  }
  
  beforeChange(e) {
    console.log('beforeChange');
  }
  constructor(private vehicleRegistrationService: VehicleRegistrationService,
    private vehicleMasterService: VehicleMasterService,
    private makeMasterService: MakeMasterService,
    private formBuilder: FormBuilder,
    private uiService: UiService,
    private regionMasterService: RegionMasterService,
    private modelMasterService: ModelMasterService,
    private commonMasterService: CommonMasterService,
    private featureMasterService: FeatureMasterService,
    private searchAllService: SearchAllService,
    private router: Router
    )
     { this.initVehicleSearch(new HomeSearch())
    }

  ngOnInit() {
    var d = new Date();
    var n = d.getFullYear();

    this.price=data["default"]["Price"][0]+"-"+data["default"]["Price"][1];
    this.year=data["default"]["year"]+"-"+n;
  this.getAllregistration();
  this.getAllRegionMaster();
  this.getAllVehicleMaster();
  this.getAllBrand();
  }
 

  initVehicleSearch(m: HomeSearch) {
    var d = new Date();
    var n = d.getFullYear();
    this.price=data["default"]["Price"][0]+"-"+data["default"]["Price"][1];
    this.year=data["default"]["year"]+"-"+n;
    this.minYear=data["default"]["year"];
    this.maxYear=n;
    this.minPrice=data["default"]["Price"][0];
    this.maxPrice=data["default"]["Price"][1];
    
    this.vehiclerSearchForm = this.formBuilder.group({
      tblVehicleId: [m.tblVehicleId==null? 0 : m.tblVehicleId ] ,
      tblMakeId: [m.tblMakeId==null? 0 : m.tblMakeId],
      tblModelId: [m.tblModelId==null? 0 : m.tblModelId],
      tblregionId: [m.tblregionId]==null? 0 : m.tblregionId,
      tblFuelTypeID: [m.tblFuelTypeID==null? 0 : m.tblFuelTypeID],
      year: [[data["default"]["year"],n]],
      price: [[data["default"]["Price"][0],data["default"]["Price"][1]]],
    });
    this.vehiclerSearchForm.get('price').setValue([data["default"]["Price"][0],data["default"]["Price"][1]]);
    this.vehiclerSearchForm.get('year').setValue([data["default"]["year"],n]);
    
  }
  getAllregistration() {
    this.commonMasterService.GetVehicleRegistrationlist().subscribe(res => {

    this.objvehicleRegistrationService=res.filter(x=>x.isFancy==1);
    });

  }

   // ============================================
  // region
  // =======================================
  getAllRegionMaster() {
    this.commonMasterService.RegionMasterlist().subscribe(res => {
      this.clsRegionMasterArray = res;
    });
  }
  // ===========================================
  //
  // ===========================================
  getAllVehicleMaster() {
    this.commonMasterService.VehicleMasterlist().subscribe(res => {
      this.clsVehicleMasterArray = res;
    });
  }
  chunkArrayInGroups(arr, size) {
    var myArray = [];
    for(var i = 0; i < arr.length; i += size) {
      myArray.push(arr.slice(i, i+size));
    }
    return myArray;
  }
  getAllBrand(){
    this.commonMasterService.brandlist().subscribe(res => {
    
      let UploadArray=new Array<any>();
     if( res.length>0){
       for (let index = 0; index <  res.length; index++) {
         for (let index1 = 0; index1 < res[index].uploadImageList.length; index1++) {
           
          UploadArray.push( res[index].uploadImageList[0])
         }
         
       }
       this.clsMakeMasterBrandArray=this.chunkArrayInGroups(UploadArray,7);
       console.log('this.clsMakeMasterBrandArray', this.clsMakeMasterBrandArray)
     }

      //this.clsMakeMasterBrandArray = res;

     // this.featureArrayChanks= this.chunkArrayInGroups(res[0].featureId, 3);
    });
  }
  async onvehicleMasterChange(event: any) {
    const value = event.value;
    this.vehiclerSearchForm.get('tblVehicleId').setValue(value);
    this.vehiclerSearchForm.get('tblMakeId').setValue(0);
    this.vehiclerSearchForm.get('tblModelId').setValue(0);
    await this.getAllMakeMaster(value);
  }
  async getAllMakeMaster(value: any) {
    const query = value;
    this.commonMasterService.listByVehicleMasterId(query).subscribe(res => {
      this.clsMakeMasterArray = new Array<MakeMaster>();
      this.clsModelMasterArray = new Array<ModelMaster>();
      this.clsMakeMasterArray = res;
    });
  }
  async getAllModels(makeId: any) {
    this.clsModelMasterArray = new Array<ModelMaster>();
    this.commonMasterService.listModelByMakeId(makeId).subscribe(res => {
      this.clsModelMasterArray = res;
    });
  }
  async onvehicleMakeChange(event: any) {
    const value = event.value;
    await this.getAllModels(value);
    this.vehiclerSearchForm.get('tblMakeId').setValue(value);
    this.vehiclerSearchForm.get('tblModelId').setValue(null);
  }

  ViewAll(){
   
    const ss=this.vehiclerSearchForm.getRawValue() as HomeSearch;
    if(ss.tblVehicleId ==null ||ss.tblVehicleId==undefined){
      ss.tblVehicleId=0;
    }
    if(ss.tblMakeId ==null ||ss.tblMakeId==undefined){
      ss.tblMakeId=0;
    }
    if(ss.tblModelId ==null ||ss.tblModelId==undefined){
      ss.tblModelId=0;
    }
    if(ss.tblregionId ==null ||ss.tblregionId==undefined){
      ss.tblregionId=0;
    }
    if(ss.tblFuelTypeID ==null ||ss.tblFuelTypeID==undefined){
      ss.tblFuelTypeID=0;
    }
    if(ss.tbltransmitionTypeID ==null ||ss.tbltransmitionTypeID==undefined){
      ss.tbltransmitionTypeID=0;
    }
    ss.featureId=null;
    if(ss.price.length>0){
      ss.price[0]= ss.price[0].toString();
      ss.price[1]= ss.price[1].toString()
    }
    if(ss.year.length>0){
      ss.year[0]= ss.year[0].toString();
      ss.year[1]= ss.year[1].toString()
    }
    this.searchAllService.SearchHome(ss).subscribe(res=>{
 
      this.searchAllService.HomePageSearchResult.next(res);
      this.router.navigate(['listing'])
    });

  
  }
  onImageClick(evt:any){

 

    const ss= new HomeSearch();
    ss.tblVehicleId=0;
    ss.tblMakeId=evt.tblMakeMasterId;
    ss.tblModelId=0;
    ss.tblregionId=0
    ss.tblFuelTypeID=0;
    ss.featureId=[];
    ss.tbltransmitionTypeID=0;
    // ss.price.push(this.minPrice, this.maxPrice);
    // ss.year.push(this.minYear, this.maxYear);
    ss.price=[];
    ss.year=[]
    this.searchAllService.SearchHome(ss).subscribe(res=>{
      
      this.searchAllService.HomePageSearchResult.next(res);
      this.router.navigate(['listing'])
    });

    //console.log('sssss', evt)


  }
  yearChange(event:any){
    this.year=event.values[0] +"-"+ event.values[1];
  }
  priceChange(event:any){
    this.price=event.values[0] +"-"+ event.values[1];
  }
  ViewDetails(element:any){
    this.router.navigate(['details'], { queryParams: { detailsId: element.id } });
  }


}
