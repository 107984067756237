import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

const credentialsKey = 'currentUser';
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private http: HttpClient) { }
    getToken() {
        const savedCredentials = this.getUser();
        // tslint:disable-next-line:no-string-literal
        return savedCredentials;
    }

    private getUser() {
        debugger
        const savedCredentials = sessionStorage.getItem('token');
        return savedCredentials;
    }

    logout(): Observable<boolean> {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('token');
        return of(true);
    }
}
