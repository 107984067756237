import { BrowserModule, HammerGestureConfig } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, LoginComponent, RegisterComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { HomeComponent } from './home/home.component';
import { ListingComponent } from './listing/listing.component';
import { MakeComponent } from './make/make.component';
import { VehiclemasterComponent } from './vehiclemaster/vehiclemaster.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { DetailsComponent } from './details/details.component';
import { VehicleregistrationComponent } from './vehicleregistration/vehicleregistration.component';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { AdminNavigationComponent } from './admin-navigation/admin-navigation.component';
import { ToastrModule } from 'ngx-toastr';
import { ModelMasterComponent } from './model-master/model-master.component';
import { RegionComponent } from './region/region.component';
import { FeatureComponent } from './feature/feature.component';
import { LoaderComponent } from './loader/loader.component';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    pan: {
      direction: Hammer.DIRECTION_ALL,
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ListingComponent,
    MakeComponent,
    VehiclemasterComponent,
    LoginComponent,
    RegisterComponent,
    DetailsComponent,
    VehicleregistrationComponent,
    AdminNavigationComponent,
    ModelMasterComponent,
    RegionComponent,
    FeatureComponent,
    LoaderComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    NgxSpinnerModule,
    
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient],
    //   },
    //   isolate: true,
    // }),
    ToastrModule.forRoot({
      timeOut: 2500,
      positionClass: 'toast-top-right',
      progressBar: true,
      progressAnimation: 'increasing',
    }),
  AgmCoreModule.forRoot({
    apiKey: 'AIzaSyAvcDy5ZYc2ujCS6TTtI3RYX5QmuoV8Ffw'
  }),

  ],
  providers: [NgxSpinnerService,
    {provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig},
    {provide: LocationStrategy, useClass: HashLocationStrategy}

 ],

 // providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
 
  entryComponents: [LoginComponent, RegisterComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
  
 }
