import { RegionMaster } from './../models/region-master';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VehicleMaster } from '../models/vehicle-master';


@Injectable({
  providedIn: 'root'
})
export class RegionMasterService {

  constructor(private httpClient: HttpClient) {}




  list(): Observable<any> {
    return this.httpClient.get<RegionMaster[]>(`api/RegionMaster/List`);
  }

  listBysearchKeyWord( searchKey: any): Observable<any[]> {
    return this.httpClient.get<RegionMaster[]>('api/RegionMaster/search/'+ searchKey);
  }
  

  listById( queryId: any): Observable<any[]> {
    return this.httpClient.get<RegionMaster[]>('api/RegionMaster/select/'+ queryId);
  }

  create(regionMaster: RegionMaster): Observable<any> {
    return this.httpClient.post<RegionMaster>(`api/RegionMaster`, regionMaster);
  }
  update(regionMaster: RegionMaster): Observable<any> {
    return this.httpClient.put<RegionMaster>(`api/RegionMaster/`+regionMaster.id , regionMaster);
  }

  delete(regionMaster: RegionMaster): Observable<any> {
    return this.httpClient.delete(`api/RegionMaster/` + regionMaster.id );
  }

}
