import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MakeMaster } from '../models/make-master';
import { UiService } from '../core/services/ui.services';


@Injectable({
  providedIn: 'root'
})
export class MakeMasterService {
  private corsHeaders: HttpHeaders;
  constructor(private httpClient: HttpClient, private uiService: UiService) {
    
    this.corsHeaders = new HttpHeaders({
    //'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*'
  });
  }

  list(): Observable<any[]> {
    return this.httpClient.get<MakeMaster[]>(`api/MakeMaster/List`);
  }
  create(makeMaster: MakeMaster): Observable<any> {
    return this.httpClient.post<MakeMaster>(`api/MakeMaster`, makeMaster);
  }
  update(makeMaster: MakeMaster): Observable<any> {
    return this.httpClient.put<MakeMaster>(`api/MakeMaster/`+makeMaster.id , makeMaster);
  }

  delete(makeMaster: MakeMaster): Observable<any> {
    return this.httpClient.delete(`api/MakeMaster/` + makeMaster.id );
  }
  
  PhotoUplaod(fileList: FileList, id: any): Observable<any> {
    
    if (fileList === undefined || fileList === null || fileList.length === 0) {
      this.uiService.showError('No Files Detected');
      return;
    }

    const formData = new FormData();
    Array.from(fileList).forEach((file: File) => {
      // 5242880 is 5 mb in binary bytes
      if (file.size > 5242880) {
        this.uiService.showError('Size limit');
      }
      formData.append('files', file);
    });
    return this.httpClient.post<any>(`api/MakeMaster/PhotoUplaod/`+id, formData,
     {
      headers: new HttpHeaders().set('Accept', 'application/json'),
    // headers: this.corsHeaders
    }
    );
  }

  deletePhotoByid(id: any): Observable<any> {
    return this.httpClient.delete(`api/MakeMaster/DeleteUploadPhotoById/` + id );
  }

}
