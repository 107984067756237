import { environment } from 'src/environments/environment';


import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith('/assets/i18n/')) {
      return next.handle(request);
    }
    if (environment.host !== null) {
      // tslint:disable-next-line:align
     request = request.clone({ url: environment.host + request.url });
    //  request = request.clone({
      // setHeaders: {
      //   Authorization: `Bearer ${this.auth.getToken()}`
      // }
    // });
    }
    return next.handle(request);
  }
}
