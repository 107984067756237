import { Component, OnInit } from '@angular/core';

import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import * as $ from 'jquery';
import { SliderService } from '../slider.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SearchAllService } from '../services/searchAll.service';
import { VehicleRegistrationService } from '../services/vehicle-registration.service';
import { Router } from '@angular/router';
import { UiService } from '../core/services/ui.services';
import { CommonMasterService } from '../services/common.service';
import { FeatureMasterService } from '../services/feature.service';
import { MakeMasterService } from '../services/make-master.service';
import { ModelMasterService } from '../services/model-master.service';
import { RegionMasterService } from '../services/region-master.service';
import { VehicleMasterService } from '../services/vehicle-master.service';
import { MakeMaster } from '../models/make-master';
import { ModelMaster } from '../models/model-master';
import { RegionMaster } from '../models/region-master';
import { VehicleMaster } from '../models/vehicle-master';
import { HomeSearch } from '../models/search-home';
import { FeatureMaster } from '../models/feature-master';
import * as data from "../../assets/yearandprice.json"
export interface Category {
  name: string;
}
@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.css']
})
export class ListingComponent implements OnInit {

  objvehicleRegistrationService: any[] = [];
  vehiclerSearchForm: FormGroup;
  clsRegionMasterArray = Array<RegionMaster>();
  clsVehicleMasterArray = Array<VehicleMaster>();
  clsMakeMasterArray = new Array<MakeMaster>();
  clsModelMasterArray = new Array<ModelMaster>();
  featureMasterArray = new Array<FeatureMaster>();
  screenWidth: number;
  YearOpenState: any;
  BudgetOpenState: any;
  TransmissionOpenState: any;
  BrandOpenState: any;
  ChktbltransmitionTypeManual = false;
  ChktbltransmitionTypeAutomatic = false;
  price="5000"+"-"+"10000000";
  year="1800"+"-"+"2021";
  minYear:any;
  maxYear:any;
  minPrice:any;
  maxPrice:any;
  tblregionId=0;
  LuxuryOpenState=false;
  product: any =(data as any ).default;
  constructor(private vehicleRegistrationService: VehicleRegistrationService,
    private formBuilder: FormBuilder,
    private commonMasterService: CommonMasterService,
    private searchAllService: SearchAllService,
    private router: Router
  ) {
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
    };
    this.initVehicleSearch(new HomeSearch);
  }

  toppingsControl = new FormControl([]);
  toppingsControl1 = new FormControl([]);
  toppingList: string[] = ['Chennai', 'Banglore', 'Kerala'];

  onToppingRemoved(topping: string) {
    const toppings = this.toppingsControl.value as string[];
    this.removeFirst(toppings, topping);
    this.toppingsControl.setValue(toppings); // To trigger change detection
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }


  colorRemoved(topping: string) {
    const toppings = this.toppingsControl.value as string[];
    this.removeFirst(toppings, topping);
    this.toppingsControl.setValue(toppings); // To trigger change detection
  }

  val: number[] = [0, 100];
  next() {
    this.val[0]++;
  }
  ngOnInit() {
    var d = new Date();
    var n = d.getFullYear();
    this.price=data["default"]["Price"][0]+"-"+data["default"]["Price"][1];
    this.year=data["default"]["year"]+"-"+n;
    this.minYear=data["default"]["year"];
    this.maxYear=n;
    this.minPrice=data["default"]["Price"][0];
    this.maxPrice=data["default"]["Price"][1];
    this.objvehicleRegistrationService = new Array<any>();
    this.searchAllService.HomePageSearchResult.subscribe(res => {
      if (res.length > 0) {
        this.objvehicleRegistrationService = res;
      } else {
         this.getAllregistration()
      }
    });
    this.getAllRegionMaster();
    this.getAllVehicleMaster();
    this.getAllFeature();
 

  }
  onImageClick(){
    this.router.navigate(['/default']);
  }
 
  // ============================================
  // region
  // =======================================
  getAllRegionMaster() {
    this.commonMasterService.RegionMasterlist().subscribe(res => {
      this.clsRegionMasterArray = res;
    });
  }

  getAllregistration() {
    this.commonMasterService.GetVehicleRegistrationlist().subscribe(res => {
      this.objvehicleRegistrationService = res;
    });

  }
  getAllVehicleMaster() {
    this.commonMasterService.VehicleMasterlist().subscribe(res => {
      this.clsVehicleMasterArray = res;
    });
  }
  async onvehicleMasterChange(event: any) {
    const value = event.value;
    this.vehiclerSearchForm.get('tblVehicleId').setValue(value);
    this.vehiclerSearchForm.get('tblMakeId').setValue(0);
    this.vehiclerSearchForm.get('tblModelId').setValue(0);
    await this.getAllMakeMaster(value);
  }
  async getAllMakeMaster(value: any) {
    const query = value;
    this.commonMasterService.listByVehicleMasterId(query).subscribe(res => {
      this.clsMakeMasterArray = new Array<MakeMaster>();
      this.clsModelMasterArray = new Array<ModelMaster>();
      this.clsMakeMasterArray = res;
    });
  }
  async getAllModels(makeId: any) {
    this.clsModelMasterArray = new Array<ModelMaster>();
    this.commonMasterService.listModelByMakeId(makeId).subscribe(res => {
      this.clsModelMasterArray = res;
    });
  }
  async onvehicleMakeChange(event: any) {
    const value = event.value;
    await this.getAllModels(value);
    this.vehiclerSearchForm.get('tblMakeId').setValue(value);
    this.vehiclerSearchForm.get('tblModelId').setValue(null);
  }

  searchKeyValue(searchKeyword: string) {
    this.clsRegionMasterArray = Array<RegionMaster>();
    if (searchKeyword != '') {
      this.commonMasterService.listBysearchKeyWord(searchKeyword).subscribe(
        res => {
          if (res) {
            this.clsRegionMasterArray = res;
          } else {
            const removeStr = searchKeyword.substring(0, searchKeyword.length - 1);
            this.vehiclerSearchForm.controls['tblregionId'].setValue(removeStr);
            this.searchKeyValue(removeStr);
          }
        },
        error => {
          console.log(error);
        }
      );
    } else {
      this.getAllRegionMaster();
    }

  }

  initVehicleSearch(m: HomeSearch) {
    var d = new Date();
    var n = d.getFullYear();
    this.price=data["default"]["Price"][0]+"-"+data["default"]["Price"][1];
    this.year=data["default"]["year"]+"-"+n;
    this.minYear=data["default"]["year"];
    this.maxYear=n;
    this.minPrice=data["default"]["Price"][0];
    this.maxPrice=data["default"]["Price"][1];
    this.vehiclerSearchForm = this.formBuilder.group({
      tblVehicleId: [m.tblVehicleId == null ? 0 : m.tblVehicleId],
      tblMakeId: [m.tblMakeId == null ? 0 : m.tblMakeId],
      tblModelId: [m.tblModelId == null ? 0 : m.tblModelId],
      tblregionId: [m.tblregionId] == null ? 0 : m.tblregionId,
      tblFuelTypeID: [m.tblFuelTypeID == null ? 0 : m.tblFuelTypeID],
      year: [[this.minYear, this.maxYear]],
      price: [[this.minPrice, this.maxPrice]],
      tbltransmitionTypeID: [m.tbltransmitionTypeID == null ? 0 : m.tbltransmitionTypeID],
      featureId: [m.featureId]
    });
  }

  transmitionCheckManual(event) {
    
    if (event.checked == true) {
      this.ChktbltransmitionTypeManual=true;
      this.ChktbltransmitionTypeAutomatic=false;
      this.vehiclerSearchForm.controls.tbltransmitionTypeID.setValue(2);
    }
    if (event.checked == false) {
      this.ChktbltransmitionTypeManual=false;
      this.vehiclerSearchForm.controls.tbltransmitionTypeID.setValue(0);
    }
  }
  transmitionCheckAutomatic(event) {
    if (event.checked == true) {
      this.ChktbltransmitionTypeManual=false;
      this.ChktbltransmitionTypeAutomatic=true;
      this.vehiclerSearchForm.controls.tbltransmitionTypeID.setValue(1);
    }
    if (event.checked == false) {
      this.ChktbltransmitionTypeAutomatic=false;
      this.vehiclerSearchForm.controls.tbltransmitionTypeID.setValue(0);
    }
  }
  getAllFeature() {
    this.commonMasterService.FeatureMasterlist().subscribe(res => {
      if (res.length > 0) {
        this.featureMasterArray = res;
      }
    });
  }

  SelectedTypes(types) {
    this.vehiclerSearchForm.controls.featureId.setValue(
      types.value
    );
  }
  searchlist(){  
    const ss=this.vehiclerSearchForm.getRawValue() as HomeSearch;
    if(ss.featureId==null){
      ss.featureId=[];
    }
    if(ss.tblregionId ==null){
      ss.tblregionId=0;
    }
    ss.tblregionId=this.tblregionId;

    if(ss.tblVehicleId ==null ||ss.tblVehicleId==undefined){
      ss.tblVehicleId=0;
    }
    if(ss.tblMakeId ==null ||ss.tblMakeId==undefined){
      ss.tblMakeId=0;
    }
    if(ss.tblModelId ==null ||ss.tblModelId==undefined){
      ss.tblModelId=0;
    }
    if(ss.tblregionId ==null ||ss.tblregionId==undefined){
      ss.tblregionId=0;
    }
    if(ss.tblFuelTypeID ==null ||ss.tblFuelTypeID==undefined){
      ss.tblFuelTypeID=0;
    }
    if(ss.tbltransmitionTypeID ==null ||ss.tbltransmitionTypeID==undefined){
      ss.tbltransmitionTypeID=0;
    }
    ss.featureId=null;
    if(ss.price.length>0){
      ss.price[0]= ss.price[0].toString();
      ss.price[1]= ss.price[1].toString()
    }
    if(ss.year.length>0){
      ss.year[0]= ss.year[0].toString();
      ss.year[1]= ss.year[1].toString()
    }
    if(this.ChktbltransmitionTypeManual ==false && this.ChktbltransmitionTypeAutomatic==false){
      this.vehiclerSearchForm.controls.tbltransmitionTypeID.setValue(0);
    }
  

    this.searchAllService.SearchHome(ss).subscribe(res=>{
      
      this.objvehicleRegistrationService = res.filter(x=>x.isShow==1);
    });
  }
  
  selectedOrgTypes(a, b) {
    // tslint:disable-next-line:no-unused-expression
    return a === b;
  }
  yearChange(event:any){
    this.year=event.values[0] +"-"+ event.values[1];
  }
  priceChange(event:any){
    this.price=event.values[0] +"-"+ event.values[1];
  }
  setRegionValue(id:number){
  this.tblregionId=id;
  }
  ViewDetails(element:any){
    this.router.navigate(['details'], { queryParams: { detailsId: element.id } });
  }



}
