import { Component, OnInit, ViewChild } from '@angular/core';
import { ModelMaster } from '../models/model-master';
import { VehicleMasterService } from '../services/vehicle-master.service';
import { MakeMasterService } from '../services/make-master.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UiService } from '../core/services/ui.services';
import { VehicleMaster } from '../models/vehicle-master';
import { MakeMaster } from '../models/make-master';
import { ModelMasterService } from '../services/model-master.service';
import { MatTableDataSource, MatPaginator, MatSlideToggleChange } from '@angular/material';

@Component({
  selector: 'app-model-master',
  templateUrl: './model-master.component.html',
  styleUrls: ['./model-master.component.css']
})
export class ModelMasterComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns: string[] = ['Id', 'VehicleName', 'tblmakemasterName', 'ModelName', 'IsActive', 'EditDelete'];
  dataSource: any;
  screenWidth: number;
  modelMasterForm: FormGroup;
  clsVehicleMasterArray = new Array<VehicleMaster>();
  clsMakeMasterArray = new Array<MakeMaster>();
  clsModelMasterArray = new Array<ModelMaster>();
  ClsModelaster = new ModelMaster();
  // paginator: any;
  constructor(private vehicleMasterService: VehicleMasterService,
              private makeMasterService: MakeMasterService,
              private modelMasterService: ModelMasterService,
              private formBuilder: FormBuilder,
              private uiService: UiService) {
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
    };
    this.initModelasterForm(new ModelMaster());
  }

  ngOnInit() {
    this.getAllVehicleMaster();
    this.getAllModels();
    this.modelMasterForm.controls.isActive.setValue(1);
  }

  initModelasterForm(m: ModelMaster) {
    this.modelMasterForm = this.formBuilder.group({
      id: [m.id == null ? 0 : m.id],
      modelName: [m.modelName == null ? '' : m.modelName, Validators.required],
      isActive: [m.isActive, Validators.required],
      tblmakemasterId: [m.tblmakemasterId == null ? '' : m.tblmakemasterId, Validators.required],
      tblVehiclemasterId: [m.tblVehiclemasterId == null ? '' : m.tblVehiclemasterId, Validators.required],
    });
  }

  getAllVehicleMaster() {
    this.vehicleMasterService.list().subscribe(res => {
      this.clsVehicleMasterArray = res;
    });
  }
  onvehicleMasterChange(event: any) {
    
    this.clearAll();
    const value = event.value;
    this.modelMasterForm.get('tblVehiclemasterId').setValue(value);
    this.getAllMakeMaster(value);
  }
  onvehicleMakeChange(event: any) {
    const value = event.value;
    this.modelMasterForm.get('tblmakemasterId').setValue(value);
    this.modelMasterForm.get('modelName').setValue('');
  }

  clearAll() {
    const editValue = this.modelMasterForm.getRawValue();
    if (editValue.id === 0) {
      this.modelMasterForm.patchValue({
        id: 0,
        modelName: '',
        isActive: 1,
        tblmakemasterId: 0,
        tblVehiclemasterId: 0,
      });
    } else {
      this.modelMasterForm.patchValue({
        id: editValue.id,
        modelName: '',
        isActive: 1,
        tblmakemasterId: 0,
        tblVehiclemasterId: 0,
      });
    }


  }

  getAllMakeMaster(value: any) {
    const query = value ;
    this.modelMasterService.listById(query).subscribe(res => {
      this.clsMakeMasterArray = new Array<MakeMaster>();
      this.clsMakeMasterArray = res;
    });
  }
  getAllModels() {
    //const query = ' C.Id <> \'\' and C.IsActive=1 And A.IsActive=1 And B.IsActive=1 ';
    this.modelMasterService.listAllModels().subscribe(res => {

      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
      this.clsModelMasterArray = res;
    });
  }
  Edit(element: any) {
    
    const clsModelMaster = new ModelMaster();
    clsModelMaster.id = element.id;
    clsModelMaster.modelName = element.modelName;
    clsModelMaster.isActive = element.isActive;
    clsModelMaster.tblmakemasterId = element.tblmakemasterId;
    clsModelMaster.tblmakemasterName = element.tblmakemasterName;
    clsModelMaster.tblVehiclemasterId = element.tblVehiclemasterId;
    clsModelMaster.vehicleName = element.vehicleName;
    this.getAllMakeMaster(clsModelMaster.tblVehiclemasterId);
    this.initModelasterForm(clsModelMaster);
  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  save() {

    this.ClsModelaster = this.modelMasterForm.getRawValue();
    if (this.ClsModelaster.id === 0) {
      this.uiService
        .showConfirmationBox('Create', 'Are you sure you want to create ?....')
        .subscribe(result => {
          if (result) {
            
            this.modelMasterService.create(this.ClsModelaster).subscribe(res => {

              if(res!='modal-exist'){
              this.uiService.showSuccess(`${res.modelName} was created`);
              this.ClsModelaster = new ModelMaster();
              this.initModelasterForm(new ModelMaster());
              this.cancel();
              } else{
                this.uiService.showError(`${this.ClsModelaster.modelName} already exists`);
              }
            });
          }
        });
    } else {
      this.uiService
        .showConfirmationBox('Update', 'Are you sure you want to update ?...')
        .subscribe(result => {
          if (result) {
          
            this.modelMasterService.update(this.ClsModelaster).subscribe(res => {
              if(res!='modal-exist'){
              this.uiService.showSuccess(`${res.modelName} was updated`);
              this.ClsModelaster = new ModelMaster();
              this.initModelasterForm(new ModelMaster());
              this.cancel();
              } else {
                this.uiService.showError(`${this.ClsModelaster.modelName} already exists`);
              }

            });
          }
        });
    }

  }

  cancel() {
    this.initModelasterForm(new ModelMaster());
    this.getAllVehicleMaster();
    this.getAllModels();
    this.modelMasterForm.controls.isActive.setValue(1);
  }

  Delete(element: any) {
    this.uiService
    .showConfirmationBox('Delete', 'Are you sure you want to delete ?...')
    .subscribe(result => {
      if (result) {
        this.modelMasterService.delete(element.id).subscribe(res => {
          if (res.id > 0) {
            this.uiService.showSuccess(`${element.modelName} was deleted`);
            this.initModelasterForm(new ModelMaster());
            this.getAllVehicleMaster();
            this.getAllModels();
          }

        });
      }
      });

  }

  public toggle(event: MatSlideToggleChange) {
    
    const checkedValue = event.checked;
    if(checkedValue == true){
      this.modelMasterForm.controls.isActive.setValue(1);
    }
    if(checkedValue == false){
      this.modelMasterForm.controls.isActive.setValue(0);
    }
  }

}
