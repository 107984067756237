import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UiService } from '../core/services/ui.services';
import { FeatureMaster } from '../models/feature-master';
import { MatPaginator, MatSlideToggleChange, MatSort } from '@angular/material';
import { FeatureMasterService } from '../services/feature.service';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.css']
})
export class FeatureComponent implements OnInit {
  featureMaster = new FeatureMaster();
  screenWidth: number;
  featuresForm: FormGroup;
  // tslint:disable-next-line:align

  constructor(
    private formBuilder: FormBuilder,
    private uiService: UiService,
    private featureMasterService: FeatureMasterService,
  ) {
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
    };
    this.initFeatureForm(new FeatureMaster());
  }
  FeatureMasterArray = Array<FeatureMaster>();
  clsFeatureMaster = new FeatureMaster();

  displayedColumns: string[] = ['Id', 'FeatureType', 'IsActive', 'EditDelete'];
  dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.getAllFeatureMaster();
  }
  initFeatureForm(m: FeatureMaster) {
    this.featuresForm = this.formBuilder.group({
      id: [m.id == null ? 0 : m.id ],
      featureType: [m.featureType == null ? '' : m.featureType , Validators.required],
      isActive: [m.isActive , Validators.required],
    });
  }
  getAllFeatureMaster() {
    this.featuresForm.controls.isActive.setValue(1);
    this.featureMasterService.list().subscribe(res => {
      this.FeatureMasterArray = res;
      this.dataSource = this.FeatureMasterArray;
      this.dataSource.paginator = this.paginator;

    });
  }
  create() {
    this.clsFeatureMaster = this.featuresForm.getRawValue();
    if (this.clsFeatureMaster.id === 0) {
      this.uiService
        .showConfirmationBox('Create', 'Are you sure you want to create ?....')
        .subscribe(result => {
          if (result) {
            this.featureMasterService.create(this.clsFeatureMaster).subscribe(res => {
              if(res!='feature-exist'){
              this.uiService.showSuccess(`${res.featureType} was created`);
              this.clsFeatureMaster = new FeatureMaster();
              this.initFeatureForm( new FeatureMaster());
              this.getAllFeatureMaster();
              } else {
                this.uiService.showError(`${this.clsFeatureMaster.featureType} already exists`);   
              }
            });
          }
        });
    } else {
      this.uiService
        .showConfirmationBox('Update', 'Are you sure you want to update ?...')
        .subscribe(result => {
          if (result) {
            this.featureMasterService.update(this.clsFeatureMaster).subscribe(res => {
              if(res!='feature-exist'){
              this.uiService.showSuccess(`${res.featureType} was updated`);
              this.clsFeatureMaster = new FeatureMaster();
              this.initFeatureForm( new FeatureMaster());
              this.getAllFeatureMaster();
              } else{
                this.uiService.showError(`${this.clsFeatureMaster.featureType} already exists`);   
              }
            });
          }
          });
    }
  }
  Delete(element: any) {
    const clsVehicleMaster = new FeatureMaster();
    clsVehicleMaster.id = element.id;
    clsVehicleMaster.featureType = element.featureType;
    clsVehicleMaster.isActive = element.isActive;
    this.uiService
    .showConfirmationBox('Delete', 'Are you sure you want to delete ?...')
    .subscribe(result => {
      if (result) {
        this.featureMasterService.delete(clsVehicleMaster).subscribe(res => {
          if (res.id > 0) {
            this.uiService.showSuccess(`${clsVehicleMaster.featureType} was deleted`);
            this.clsFeatureMaster = new FeatureMaster();
            this.initFeatureForm(new FeatureMaster());
            this.getAllFeatureMaster();
          }

        });
      }
      });

  }
  Edit(element: any) {
    const clsVehicleMaster = new FeatureMaster();
    clsVehicleMaster.id = element.id;
    clsVehicleMaster.featureType = element.featureType;
    clsVehicleMaster.isActive = element.isActive;
    this.initFeatureForm(clsVehicleMaster);
  }
  cancel() {
    this.initFeatureForm( new FeatureMaster());
    this.getAllFeatureMaster();
  }

  public toggle(event: MatSlideToggleChange) {
    
    const checkedValue = event.checked;
    if(checkedValue == true){
      this.featuresForm.controls.isActive.setValue(1);
    }
    if(checkedValue == false){
      this.featuresForm.controls.isActive.setValue(0);
    }
  }

}
