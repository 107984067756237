import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VehicleMaster } from '../models/vehicle-master';
import { FeatureMaster } from '../models/feature-master';
import { UiService } from '../core/services/ui.services';
import { FuleTypeMaster } from '../models/fuleType-master';
import { OwnerTypeMaster } from '../models/ownertype-master';
import { TransmitionTypeMaster } from '../models/Transmition-type-master';
import { VehicleRegistarion } from '../models/vehicle-registration';
import { RegionMaster } from '../models/region-master';
import { MakeMaster } from '../models/make-master';
import { ModelMaster } from '../models/model-master';
import { InterestedEmailModel } from '../models/email-body';
import { EmailModel } from '../app.component';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CommonMasterService {

  //constructor(private httpClient: HttpClient, private uiService: UiService) {}

  private httpClient: HttpClient;

  constructor( httpBackend: HttpBackend, private uiService: UiService, private http: HttpClient) { 
     this.httpClient = new HttpClient(httpBackend);
  }

  driveModeList(): Observable<any> {
    return this.httpClient.get<TransmitionTypeMaster[]>(environment.host+`api/Common/GetTransmitionMasterList`);
  }
  fuleTypeList(): Observable<any> {
    return this.httpClient.get<FuleTypeMaster[]>(environment.host+`api/Common/GetFuleTypeMasterList`);
  }
  ownertypeList(): Observable<any> {
    return this.httpClient.get<OwnerTypeMaster[]>(environment.host+`api/Common/GetOwnerTypeMasterList`);
  }
  uploadFiles(fileList: FileList) {
    if (fileList === undefined || fileList === null || fileList.length === 0) {
      this.uiService.showError('No Files Detected');
      return;
    }
    const formData = new FormData();
    Array.from(fileList).forEach((file: File) => {
      // 5242880 is 5 mb in binary bytes
      if (file.size > 5242880) {
        this.uiService.showError('Size limit');
      }
      formData.append('files', file);
    });
    return this.httpClient.post<any>(environment.host+`fileUploads/uploads`, formData, {
      headers: new HttpHeaders().set('Accept', 'application/json'),
    });
  }
  GetVehicleRegistrationlist(): Observable<any[]> {
    return this.httpClient.get<VehicleRegistarion[]>(environment.host+`api/Common/GetVehicleRegistration/List`);
  }
  RegionMasterlist(): Observable<any> {
    return this.httpClient.get<RegionMaster[]>(environment.host+`api/Common/GetRegion/List`);
  }
  VehicleMasterlist(): Observable<any> {
    return this.httpClient.get<VehicleMaster>(environment.host+`api/Common/GetVehicle/List`);
  }
  listByVehicleMasterId( queryId: any): Observable<any[]> {
    return this.httpClient.get<MakeMaster[]>(environment.host+'api/Common/select/listByVehicleId/'+ queryId);
  }
  listModelByMakeId( queryId: any): Observable<any[]> {
    return this.httpClient.get<ModelMaster[]>(environment.host+'api/Common/select/listByMakeId/'+ queryId);
  }
  listBysearchKeyWord( searchKey: any): Observable<any[]> {
    return this.httpClient.get<RegionMaster[]>(environment.host+'api/Common/search/'+ searchKey);
  }
  FeatureMasterlist(): Observable<any[]> {
    return this.httpClient.get<FeatureMaster[]>(environment.host+`api/Common/GetFeatureMaster/List`);
  }
  // sendMail(emailModel: EmailModel): Observable<any> {
  //   return this.httpClient.post<any>(`api/Email`, emailModel);
  // }
  sendInterestedMail(interestedEmailModel: InterestedEmailModel): Observable<any> {
    return this.http.post<any>(`api/Email/InterestedsendEmail`, interestedEmailModel);
  }
  sellMycarInterestedMail(emailModel: EmailModel): Observable<any> {
    return this.http.post<any>(`api/Email/sendSellMycarEmail`, emailModel);
  }

  brandlist(): Observable<any[]> {
    return this.httpClient.get<MakeMaster[]>(environment.host+`api/Common/select/AllBrand`);
  }



}
