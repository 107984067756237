import { ToastrService } from 'ngx-toastr';
 

import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatSnackBar,
  MatSnackBarConfig,
} from '@angular/material';

import { Observable } from 'rxjs';
import { SimpleDialogComponent } from '../components/simple-dialog/simple-dialog.component';
import { SimpleConfirmationBoxComponent } from '../components/simple-confirmation-box/simple-confirmation-box.component';
@Injectable({
  providedIn: 'root',
})
export class UiService {
  constructor(
    private toastr: ToastrService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,

  ) { }
  showSuccess(message: string) {
    this.snackBar.open(message, 'Success', { duration: 5000 });
  }
  showInfo(message: string) {
    this.toastr.info(message, 'Info');
  }

  showWarning(message: string) {
    this.toastr.warning(message, 'Warning');
  }

  showError(message: string) {
    
    this.snackBar.open(message, 'Error', { duration: 5000 });
  }

  showToast(message: string, action = 'Close', config?: MatSnackBarConfig) {
    this.snackBar.open(
      message,
      action,
      config || {
        duration: 3000,
      }
    );
  }

  showDialog(
    title: string,
    content: string,
    okText = 'OK',
    cancelText?: string,
    customConfig?: MatDialogConfig
    // tslint:disable-next-line:ban-types
  ): Observable<Boolean> {
    const dialogRef = this.dialog.open(
      SimpleDialogComponent,
      customConfig || {
        width: '300px',
        // tslint:disable-next-line:object-literal-shorthand
        data: { title: title, content: content, okText: okText, cancelText: cancelText },
      }
    );

    return dialogRef.afterClosed();
  }

  showConfirmationBox(
    title: string,
    content: string,
    okText = 'OK',
    cancelText = 'Cancel',
    customConfig?: MatDialogConfig
    // tslint:disable-next-line:ban-types
  ): Observable<Boolean> {
    const dialogRef = this.dialog.open(
      SimpleConfirmationBoxComponent,
      customConfig || {
        width: '500px',
        // tslint:disable-next-line:object-literal-shorthand
        data: { title: title, content: content, okText: okText, cancelText: cancelText },
      }
    );

    return dialogRef.afterClosed();
  }
}
