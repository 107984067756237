import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(value: string): any {
    console.log(value)
    const gmtDateTime = moment( new Date(value) , 'YYYY-MM-DD  HH:mm:ss');

    if (moment(gmtDateTime).format('hh:mm:ss a') === '12:00:00 am') {
      const local = gmtDateTime.local().format('DD-MM-YYYY');
      return local;
    } else {
      const local = gmtDateTime.local().format('DD-MM-YYYY');
      return local;
    }

    // if (value != null && value !== undefined && value !== '') {
    //   return moment(gmtDateTime).format('ll h:mm A'); // --> localStorage.getItem('dateFormat') sessionStorage
    // }
  }
}
