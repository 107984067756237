import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VehicleMaster } from '../models/vehicle-master';
import { FeatureMaster } from '../models/feature-master';


@Injectable({
  providedIn: 'root'
})
export class FeatureMasterService {

  constructor(private httpClient: HttpClient) { }
  

  list(): Observable<any[]> {
    return this.httpClient.get<FeatureMaster[]>(`api/FeatureMaster/List`);
  }
  listBuId(id: number): Observable<any> {
    return this.httpClient.get<FeatureMaster>(`api/FeatureMaster/select/` + id);
  }
  create(featureMaster: FeatureMaster): Observable<any> {
    return this.httpClient.post<VehicleMaster>(`api/FeatureMaster`, featureMaster);
  }
  update(featureMaster: FeatureMaster): Observable<any> {
    return this.httpClient.put<VehicleMaster>(`api/FeatureMaster/` + featureMaster.id, featureMaster);
  }
  delete(featureMaster: FeatureMaster): Observable<any> {
    return this.httpClient.delete(`api/FeatureMaster/` + featureMaster.id);
  }

}
