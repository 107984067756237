import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Injectable } from '@angular/core';
import { Moment } from 'moment';
@Injectable()
export class CustomDateAdapter extends MomentDateAdapter {

  parse(value: any, parseFormat: string | string[]): Moment | null {
    const dateFormat: string | string[] =  'DD-MM-YYYY' || parseFormat;
    return super.parse(value, dateFormat);
  }

  format(date: Moment, displayFormat: string): string {
      const dateFormat: string | string[] = 'DD-MM-YYYY' || displayFormat;
      return super.format(date, dateFormat);

  }
}
