import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { ErrorHandlerInterceptor } from './interceptors/error-handler.interceptor';
import { ApiPrefixInterceptor } from './interceptors/api-prefix.interceptor';
import { SimpleDialogComponent } from './components/simple-dialog/simple-dialog.component';
import { SimpleConfirmationBoxComponent } from './components/simple-confirmation-box/simple-confirmation-box.component';
import { MaterialModule } from '../shared/modules/material.module';
import { LoaderInterceptorService } from './interceptors/loader.interceptor';
@NgModule({
    declarations: [SimpleDialogComponent, SimpleConfirmationBoxComponent],
    entryComponents: [SimpleDialogComponent, SimpleConfirmationBoxComponent],
    imports: [CommonModule, HttpClientModule, MaterialModule],
    exports: [],
    providers: [
      AuthService, // Passwd@12345
      AuthGuard,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpTokenInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: ErrorHandlerInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: ApiPrefixInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: LoaderInterceptorService,
        multi: true
      }
    ],
  })
  export class CoreModule {
    // constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    //     throw new Error('CoreModule has already been loaded. You should only import Core modules in the AppModule only.');
    // }
  }
