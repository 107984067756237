import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MakeMaster } from '../models/make-master';
import { ModelMaster } from '../models/model-master';


@Injectable({
  providedIn: 'root'
})
export class ModelMasterService {

  constructor(private httpClient: HttpClient) {}


  listById( queryId: any): Observable<any[]> {

    return this.httpClient.get<MakeMaster[]>('api/MakeMaster/select/'+ queryId);

  }

  listByVehicleMasterId( queryId: any): Observable<any[]> {
    return this.httpClient.get<MakeMaster[]>('api/MakeMaster/select/listByVehicleId/'+ queryId);
  }

  listModelById( queryId: any): Observable<any[]> {
    return this.httpClient.get<ModelMaster[]>('api/ModalMaster/select/'+ queryId);
  }
  listModelByMakeId( queryId: any): Observable<any[]> {
    return this.httpClient.get<ModelMaster[]>('api/ModalMaster/select/listByMakeId/'+ queryId);
  }

  listAllModels(): Observable<any[]> {
    return this.httpClient.get<MakeMaster[]>('api/ModalMaster/List');

  }
  create(modelMaster: ModelMaster): Observable<any> {
    return this.httpClient.post<ModelMaster>(`api/ModalMaster`, modelMaster);
  }
  update(modelMaster: ModelMaster): Observable<any> {
    return this.httpClient.put<ModelMaster>(`api/ModalMaster/`+ modelMaster.id, modelMaster);
  }

  delete(id: number): Observable<any> {
    return this.httpClient.delete(`api/ModalMaster/` + id );
  }

}
