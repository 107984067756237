export class Details {
createdBy?:number;
createdDate?:string
featureId=new Array<any>();
fuleType?:string;
id?: number
isActive?:number
isShow?: number
kilomeators?: string
makeName?: string
modelName?: string
ownerType?:string
price?: string
regionCode?: string
regionName?: string
tblFuelTypeID?: number
tblMakeId?: number
tblModelId?: number
tblOwnerTypeId?: number;
tblVehicleId?: number;
tblregionId?: number;
tbltransmitionTypeID?: number;
transmitionType?: string
updatedBy?: string
updatedDate?: string
uploadImageList=new Array<any>();
vehicleName?: string;
year?:string;
}