import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { VehicleMaster } from '../models/vehicle-master';
import { HttpParams } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class SearchAllService {
    public HomePageSearchResult: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    constructor(private httpClient: HttpClient) {
        // this.getJSON().subscribe(res=>{
        //      console.log('dddddddddddd', res)
        //          });
        

    }
    public getJSON(): Observable<any> {
        return this.httpClient.get("../../assets/yearandprice.json");
    }


    SearchHome(data: any): Observable<any> {
        
        return this.httpClient.post<any>(`api/Search/HomePagePrimarySearch`, data);
    }

    SearchByDetails(queryId: any): Observable<any[]> {
        return this.httpClient.get<any[]>('api/Search/VehicleRegistrationById/' + queryId);
    }




}