import { Component } from '@angular/core';
import { OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatTableModule, getMatFormFieldPlaceholderConflictError, MatSlideToggleChange } from '@angular/material';
import { VehicleMasterService } from '../services/vehicle-master.service';
import { VehicleMaster } from '../models/vehicle-master';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UiService } from '../core/services/ui.services';
 
@Component({
  selector: 'app-vehiclemaster',
  templateUrl: './vehiclemaster.component.html',
  styleUrls: ['./vehiclemaster.component.css']
})
export class VehiclemasterComponent implements OnInit {
  screenWidth: number;
  vehiclemasterForm: FormGroup;
  // tslint:disable-next-line:align

  constructor(
    private vehicleMasterService: VehicleMasterService,
    private formBuilder: FormBuilder,
    private uiService: UiService,

  ) {
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
    };
    this.initVehiclemasterForm(new VehicleMaster());
   
  }
  VehicleMaster = Array<VehicleMaster>();
  clsVehicleMaster = new VehicleMaster();

  displayedColumns: string[] = ['Id', 'VehicleName', 'IsActive', 'EditDelete'];
  dataSource: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.getAllVehicleMaster();
  }
  getAllVehicleMaster() {
    this.vehiclemasterForm.controls.isActive.setValue(1);
    this.vehicleMasterService.list().subscribe(res => {
      this.VehicleMaster = res;
      this.dataSource = this.VehicleMaster;
      this.dataSource.paginator = this.paginator;

    });
  }
  initVehiclemasterForm(m: VehicleMaster) {
    this.vehiclemasterForm = this.formBuilder.group({
      id: [m.id == null ? 0 : m.id ],
      vehicleName: [m.vehicleName == null ? '' : m.vehicleName , Validators.required],
      isActive: [m.isActive , Validators.required],
    });
  }
  public toggle(event: MatSlideToggleChange) {
    
    const checkedValue = event.checked;
    if(checkedValue == true){
      this.vehiclemasterForm.controls.isActive.setValue(1);
    }
    if(checkedValue == false){
      this.vehiclemasterForm.controls.isActive.setValue(0);
    }
  }
  create() {
    this.clsVehicleMaster = this.vehiclemasterForm.getRawValue();
    
    if (this.clsVehicleMaster.id === 0) {
      this.uiService
        .showConfirmationBox('Create', 'Are you sure you want to create ?....')
        .subscribe(result => {
          if (result) {
              this.vehicleMasterService.create(this.clsVehicleMaster).subscribe(res => {
                if(res!='vehicle-exist'){
                  this.uiService.showSuccess(`${res.vehicleName} was created`);
                  this.clsVehicleMaster = new VehicleMaster();
                  this.initVehiclemasterForm( new VehicleMaster());
                  this.getAllVehicleMaster();
                }else{
                  this.uiService.showError(`${this.clsVehicleMaster.vehicleName} already exists`);
                }
              
            });
          }
        });
    } else {
      this.uiService
        .showConfirmationBox('Update', 'Are you sure you want to update ?...')
        .subscribe(result => {
          if (result) {           
            this.vehicleMasterService.update(this.clsVehicleMaster).subscribe(res => {
              
              if(res!='vehicle-exist'){
              this.uiService.showSuccess(`${res.vehicleName} was updated`);
              this.clsVehicleMaster = new VehicleMaster();
              this.initVehiclemasterForm( new VehicleMaster());
              this.getAllVehicleMaster();
              }
              else{
                this.uiService.showError(`${this.clsVehicleMaster.vehicleName} already exists`);
              }
            });
        
          }
          });
    }
  }
  Edit(element: any) {
    const clsVehicleMaster = new VehicleMaster();
    clsVehicleMaster.id = element.id;
    clsVehicleMaster.vehicleName = element.vehicleName;
    clsVehicleMaster.isActive = element.isActive;
    this.initVehiclemasterForm(clsVehicleMaster);
  }
  cancel() {
    this.initVehiclemasterForm( new VehicleMaster());
    this.getAllVehicleMaster();
  }
 
  Delete(element: any) {
    
    const clsVehicleMaster = new VehicleMaster();
    clsVehicleMaster.id = element.id;
    clsVehicleMaster.vehicleName = element.vehicleName;
    clsVehicleMaster.isActive = element.isActive;
    this.uiService
    .showConfirmationBox('Delete', 'Are you sure you want to delete ?...')
    .subscribe(result => {
      if (result) {
        this.vehicleMasterService.delete(clsVehicleMaster).subscribe(res => {
          if (res.id > 0) {
            this.uiService.showSuccess(`${clsVehicleMaster.vehicleName} was deleted`);
            this.clsVehicleMaster = new VehicleMaster();
            this.initVehiclemasterForm(new VehicleMaster());
            this.getAllVehicleMaster();
          }

        });
      }
      });

  }
}
